import { datadogRum } from '@datadog/browser-rum'
import { Image } from 'plotly.js'
import { toImage } from 'plotly.js/dist/plotly-axon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ScatterPlotMetadata } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useElementById from 'utils/useElementById'
import { HIDDEN_COLOR } from '../shared'
import { CellInfo } from '../tsv/types'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import getMarkerSize from './getMarkerSize'
import { usePlotData } from './usePlotData'
import { getMetadata } from './utils'

const buffer = 5

const getImage = (
  imgUrl: string,
  position: { x: number; y: number },
  size: { height: number; width: number }
) => {
  return {
    x: position.x,
    y: position.y,
    xref: 'x',
    yref: 'y',
    xanchor: 'center',
    yanchor: 'middle',
    sizex: size.width,
    sizey: size.height,
    sizing: 'stretch',
    source: imgUrl,
    layer: 'below',
  } as Partial<Image>
}

type GetImageUrlParams = {
  scatterPlotMetaData: ScatterPlotMetadata
  /**
   * Default to a big enough image so that when the user zooms in the image doesn't get too grainy
   */
  width?: number
  height?: number
  markerSize?: number
  cellsData?:CellInfo[]
  prefilteredCellIdSet:Set<string>
  isPreFilter:boolean
}

const getImageUrl = async ({
  scatterPlotMetaData,
  width = 2000,
  height = 2000,
  markerSize = 1,
  cellsData,
  prefilteredCellIdSet,
  isPreFilter
}: GetImageUrlParams): Promise<string | null> => {
  const { maxX, maxY, minX, minY, x, y } = scatterPlotMetaData
  // This effectively removes any cells that were marked for prefiltering while keeping all other cells,
  // which is exactly what we want for rendering the background images
  const visibleCells = cellsData?.filter(cell => !prefilteredCellIdSet.has(cell.CELL_ID))
  try {
    const url = await toImage(
      {
        data: [
          {
            x: isPreFilter ? x : visibleCells?.map(cell => +cell.UMAP_0),
            y: isPreFilter ? y : visibleCells?.map(cell => +cell.UMAP_1),
            type: 'scattergl',
            mode: 'markers',
            marker: {
              color: HIDDEN_COLOR,
              size: markerSize,
            },
          },
        ],
        layout: {
          margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
          xaxis: {
            visible: false,
            range: [minX - buffer, maxX + buffer],
          },
          yaxis: {
            visible: false,
            range: [minY - buffer, maxY + buffer],
          },
          showlegend: false,
        },
      },
      { scale: 1, format: 'png', width, height }
    )
    return url
  } catch (error) {
    console.error('Error generating image URL:', error)
    // In case of error, log to datadogRum as well
    datadogRum.addError(error)
    return null
  }
}

export const useHiddenBackgroundImages = (): Partial<Image>[] => {
  const {
    visibleSelectedComparisonDimensions,
    cellVisualizations: { cellsData, morphotypesToRemove },
    prefilteredCellIdSet,
  } = useCellVisualizationsSlice()
  const { isPreFilter } = useCellVisualizationUrlParams()
  const { scatterPlotMetaData } = usePlotData()

  const filteredScatterPlotMetaData = useMemo(
    () => getMetadata(cellsData ?? [], morphotypesToRemove),
    [cellsData, morphotypesToRemove]
  )

  const comparisonDimensionsKey = useMemo(
    () => visibleSelectedComparisonDimensions?.map(dim => dim.field).join(','),
    [visibleSelectedComparisonDimensions]
  );

  const metaData = useMemo(() => {
    return isPreFilter || visibleSelectedComparisonDimensions?.length
      ? scatterPlotMetaData
      : filteredScatterPlotMetaData
  }, [
    isPreFilter,
    visibleSelectedComparisonDimensions,
    scatterPlotMetaData,
    filteredScatterPlotMetaData,
  ])

  const { rect } = useElementById('scatter-plot')

  const [images, setImages] = useState<Partial<Image>[]>([])

  const plotDimensions = useMemo(() => {
    const { maxX, minX, maxY, minY } = metaData
    return {
      plotWidth: maxX - minX,
      plotHeight: maxY - minY,
      centerX: (maxX + minX) / 2,
      centerY: (maxY + minY) / 2,
      xOffset: (maxX - minX) * 1.5,
      yOffset: (maxY - minY) * 1.5,
    }
  }, [metaData])

  const getImageSize = useCallback(
    () => ({
      width: plotDimensions.plotWidth + buffer * 2,
      height: plotDimensions.plotHeight + buffer * 2,
    }),
    [plotDimensions.plotHeight, plotDimensions.plotWidth]
  )

  const getImages = useCallback(
    (imgUrl: string) => {
      const img = [] as Partial<Image>[]
      if (visibleSelectedComparisonDimensions) {
        const numOfColumns = visibleSelectedComparisonDimensions[0].values.length
        const totalCount =
          (visibleSelectedComparisonDimensions[1]?.values.length || 1) * numOfColumns
        for (let i = 0; i < totalCount; i += 1) {
          const col = i % numOfColumns
          const row = Math.floor(i / numOfColumns)
          img.push(
            getImage(
              imgUrl,
              {
                x: plotDimensions.centerX + plotDimensions.xOffset * col,
                y: plotDimensions.centerY - plotDimensions.yOffset * row,
              },
              getImageSize()
            )
          )
        }
      } else {
        img.push(
          getImage(imgUrl, { x: plotDimensions.centerX, y: plotDimensions.centerY }, getImageSize())
        )
      }

      return img
    },
    [
      plotDimensions.centerX,
      plotDimensions.centerY,
      getImageSize,
      visibleSelectedComparisonDimensions,
      plotDimensions.xOffset,
      plotDimensions.yOffset,
    ]
  )

  useEffect(() => {
    const loadImages = async () => {
      if (!metaData.x.length) return

      const markerSize = getMarkerSize(metaData.x.length)
      const { height, width } = rect ?? {}
      const url = await getImageUrl({
        scatterPlotMetaData: metaData,
        width,
        height,
        markerSize,
        cellsData,
        prefilteredCellIdSet,
        isPreFilter,
      })
      if (url) {
        const img = getImages(url)
        if (img.length) {
          setImages(img)
        }
      }
    }
    loadImages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cellsData?.length,
    morphotypesToRemove,
    prefilteredCellIdSet.size,
    comparisonDimensionsKey,
    rect?.width,
    rect?.height,
    isPreFilter,
    metaData,
    getImages
  ])

  return images
}

export default useHiddenBackgroundImages
