import { createContext, useContext, useEffect, useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import {
  ScatterPlotData,
  defaultMetaData,
} from 'redux/slices/types'
import { CellInfo } from '../tsv/types'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import getScatterPlotDataFromCellInfo from './getScatterPlotDataFromCellInfo'
import updateWithVisibleDensityColors from './updateWithVisibleDensityColors'
import { useBaseCellPlotData } from './useBaseCellPlotData'
import useCellData from './useCellData'
import { getMetadata } from './utils'

// Storing the scatter plot data in redux (apparently?) doesn't work, so we have to resort to react context
// Storing and updating the scatterplot data all in one place should speed things up
// https://stackoverflow.com/questions/76126033/how-to-use-redux-with-react-plotly-js

const getDefaultScatterState = () =>
  ({
    plotData: [],
    cellInfo: [],
    scatterPlotMetaData: { ...defaultMetaData },
  } as ScatterPlotData)

export const PlotDataContext = createContext<ReturnType<typeof usePlotData>>(
  getDefaultScatterState()
)

export const PlotDataContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const { isPreFilter } = useCellVisualizationUrlParams()

  const [scatterPlotData, setScatterPlotData] = useState<ScatterPlotData>(getDefaultScatterState())
  const [plotMetadata, setPlotMetadata] = useState<typeof defaultMetaData>({ ...defaultMetaData })
  const [processedCellsData, setProcessedCellsData] = useState<CellInfo[]>()

  const {
    cellVisualizations: { cellsData, plotDisplayType, morphotypesToRemove },
    visibleSelectedComparisonDimensions,
  } = useCellVisualizationsSlice()

  const { getBaseCellPlotData: getBasePlot } = useBaseCellPlotData()
  const { getDataForCell } = useCellData()

  // Stage 1: Process metadata and apply prefiltering (if we're not in the pre-filtering step)
  // This should only be done rarely -- when the prefiltering choices change
  // The result is the data used to drive the scatter plot
  // (but before applying any plotting details)
  useEffect(() => {
    if (!cellsData) return
    const metaData = getMetadata(cellsData, isPreFilter ? undefined : morphotypesToRemove)
    setPlotMetadata(metaData)
    setProcessedCellsData(cellsData)
  }, [cellsData, isPreFilter, morphotypesToRemove])

  // Stage 2: Generate plot data based on the underlying raw data from Stage 1
  // This step depends on current plot layout settings (e.g. plot type, coloring method, etc.)
  useEffect(() => {
    if (!processedCellsData) return

    const latestPlotData = getScatterPlotDataFromCellInfo({
      scatterPlotMetaData: plotMetadata,
      cellInfo: processedCellsData,
      getDataForCell,
      getBasePlot,
      numOfColumns: visibleSelectedComparisonDimensions
        ? visibleSelectedComparisonDimensions[0].values.length
        : undefined,
    })

    if (plotDisplayType === 'density') {
      const calculateSeparately = Boolean(
        visibleSelectedComparisonDimensions && visibleSelectedComparisonDimensions.length > 0
      )
      updateWithVisibleDensityColors(latestPlotData.plotData, calculateSeparately)
    }

    setScatterPlotData({
      ...latestPlotData,
      scatterPlotMetaData: plotMetadata,
    })
  }, [
    plotMetadata,
    processedCellsData,
    plotDisplayType,
    visibleSelectedComparisonDimensions,
    getDataForCell,
    getBasePlot,
  ])

  return <PlotDataContext.Provider value={scatterPlotData}>{children}</PlotDataContext.Provider>
}

export const usePlotData = (): ScatterPlotData => {
  const data = useContext(PlotDataContext)
  return data
}
