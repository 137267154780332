import { PlotRange } from 'components/cell-visualizations/tsv/types'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { CellPlotData, CellPlotDatum, getDefaultFlattenedPlotData, ZoomThreshold } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { usePlotData } from '../usePlotData'
import { convertToDeepcellPlotDatum, getVisiblePointsFromPoints } from './getVisiblePoints'
import { precomputeZoomThresholds } from './precomputeZoomThresholds'

// For more details about the development of this algorithm, please see this doc:
// https://deepcellbio.atlassian.net/l/cp/nDD1Vfou
//
// This algorithm provides a way to show representative images on the scatterplot
// in a way that tries to cover the space, without too much overlap
// And also to remain stable as the user zooms in and out
// i.e. the same points shown when you're zoomed out remain visible as you zoom in
// and additional points show up to fill in the space that opens up as you zoom in
//
// This hook does the following steps:
// 1) Runs the one-time precomputation of what images to show at different zoom levels
//    (this only gets re-run if the dataset changes due to filtering,
//    (or the user changes the image size or spacing adjust parameters)
// 2) Every time the hook gets called, pick out the points to show based on the
//    current zoom level, and return them

export const useZoomThresholdSampleImages = (
  range?: PlotRange,
  plotPixelWidth?: number
): CellPlotDatum[] => {
  const flattenedPlotData = getDefaultFlattenedPlotData()
  const {
    setZoomThresholds,
    cellVisualizations: {
      zoomThresholds,
      cellImagesFilter: { imageSize, spacingAdjust },
    },
  } = useCellVisualizationsSlice()
  const {
    plotData,
    scatterPlotMetaData: { maxX, minX },
  } = usePlotData()
  // Flatten the plot data and store result in flattenedPlotData
  plotData.forEach((pd: CellPlotData) => {
    flattenedPlotData.cellIds.push(...pd.cellIds)
    flattenedPlotData.x.push(...pd.x)
    flattenedPlotData.y.push(...pd.y)
    flattenedPlotData.originalX.push(...pd.originalX)
    flattenedPlotData.originalY.push(...pd.originalY)
    flattenedPlotData.plotDatum.push(...pd.plotDatum)
  })

  const dataWidth = maxX - minX
  const { cellIds, plotDatum } = flattenedPlotData

  // Keep a local copy to tell when we should recompute zoomThresholds
  const [prevData, setPrevData] = useState<{
    imageSize: typeof imageSize
    spacingAdjust: typeof spacingAdjust
    cellIds: typeof cellIds
  }>()

  // Points to show images for
  const [points, setPoints] = useState<CellPlotDatum[]>([])

  // Precompute zoomThresholds, if needed
  // This only needs to happen once and is independent of the current plot pixel width
  useEffect(() => {
    const newData = {
      imageSize,
      spacingAdjust,
      cellIds,
    }

    const dataDidChange = !prevData || !_.isEqual(prevData, newData)

    // Only compute zoomThresholds if they're not already computed
    // Or we just changed parameters
    const zoomThresholdsExist = Boolean(zoomThresholds && zoomThresholds[0].pixelWidthInPlotCoords)

    if (!flattenedPlotData || (zoomThresholdsExist && !dataDidChange)) return

    // Compute the zoom thresholds!
    const thresholds = precomputeZoomThresholds(
      _.shuffle(plotDatum),
      dataWidth,
      imageSize,
      spacingAdjust
    )

    // Update state
    setZoomThresholds(thresholds)
    setPrevData(newData)
  }, [
    cellIds,
    dataWidth,
    flattenedPlotData,
    imageSize,
    plotDatum,
    prevData,
    setZoomThresholds,
    spacingAdjust,
    zoomThresholds,
  ])

  // Pick points to show, if we have zoom thresholds available
  // This layout depends on the currently visible plot pixel width
  useEffect(() => {
    if (
      !zoomThresholds?.length ||
      plotPixelWidth === undefined ||
      range?.x2 === undefined ||
      range?.x1 === undefined
    )
      return

    const pixelWidthInPlotCoords = (range.x2 - range.x1) / plotPixelWidth

    // Find the zoom threshold to use (the one with a pixel ratio just larger than current pixel ratio)
    // Note that if you zoom out really far, the current pixel ratio may be larger than the pixel ratio
    // for zoomThresholds[0], so just use zoomThresholds[0]
    let zoomThreshold = zoomThresholds[0]
    zoomThresholds.forEach((threshold: ZoomThreshold) => {
      if (threshold.pixelWidthInPlotCoords > pixelWidthInPlotCoords) {
        zoomThreshold = threshold
      }
    })
    if (!zoomThreshold.points) {
      return
    }

    const visiblePoints = getVisiblePointsFromPoints(zoomThreshold.points, range)
    const visibleDeepcellData = convertToDeepcellPlotDatum(visiblePoints)

    setPoints(visibleDeepcellData)
  }, [plotPixelWidth, range, zoomThresholds])

  return points
}

export default useZoomThresholdSampleImages
