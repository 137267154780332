import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
    Box,
    CardContent,
    Checkbox,
    FormControlLabel,
    IconButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { PaginationParams, PinnedMorphotype, imageCountDefault } from 'redux/slices'
import { getSliceFromPagination } from 'utils/helpers'

import useFlagCondition from 'components/shared/useFlagCondition'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import ActionPlotCellImages from '../cellSelectionDrawer/ActionPlotCellImages'
import CellImagePagination from '../cellSelectionDrawer/CellImagePagination'
import { usePlotData } from '../plot/usePlotData'
import { ChangeIconButton, DeleteIconButton, MergeIconButton } from '../shared'
import { FiltersAppliedTooltip } from './FiltersAppliedTooltip'

interface PinnedGroupContentProps {
  pinnedMorphotype: PinnedMorphotype
  onChange?(e: React.MouseEvent<HTMLElement>): void
  onDelete?(): void
  onMerge?(): void
  enableMerge?: boolean
}

export const PinnedGroupContent = ({
  pinnedMorphotype,
  onChange,
  onDelete,
  onMerge,
  enableMerge,
}: PinnedGroupContentProps): JSX.Element => {
  const cellVisualizationsMergeEnabled = useFlagCondition('cellVisualizationsMergeEnabled')
  const {
    id: pinnedGroupId,
    name,
    active,
    cells: { points },
    pagination,
    cellInfoGroupsSnapshot,
    comparisonDimensionsSnapshot,
  } = pinnedMorphotype
  const disableFilterButton = !cellInfoGroupsSnapshot && !comparisonDimensionsSnapshot
  const selectedPointLength = points?.length ?? 0

  const {
    cellInfo: { length: totalPointLength },
  } = usePlotData()

  const { startIndex, endIndex } = getSliceFromPagination({
    count: selectedPointLength,
    page: pagination?.page ?? 0,
    itemsPerPage: pagination?.itemsPerPage ?? imageCountDefault,
  })

  const {
    setPinnedGroupActive,
    setPinnedGroupPagination,
    cellVisualizations: { exportCellMorphotype },
    setPinnedGroupHighlighted,
  } = useCellVisualizationsSlice()

  const [filtersAppliedId, setFiltersAppliedId] = useState(-1)

  const handlePaginationChange = (newPagination: PaginationParams) => {
    setPinnedGroupPagination({ pinnedGroupId: pinnedMorphotype.id, pagination: newPagination })
  }

  const [percentOutputText, setPercentOutputText] = useState<string>()

  useEffect(() => {
    const stillLoading = selectedPointLength && !totalPointLength
    const madeSelectionWithAllPointsHidden = !selectedPointLength && !totalPointLength

    if (percentOutputText === undefined && !stillLoading) {
      setPercentOutputText(
        madeSelectionWithAllPointsHidden
          ? 'Percentage of cells: 0%'
          : `Percentage of cells: ${((selectedPointLength / totalPointLength) * 100).toFixed(2)}%`
      )
    }
  }, [percentOutputText, selectedPointLength, totalPointLength])

  const activeGroup = useMemo(() => {
    return exportCellMorphotype?.find((pinnedCell) => pinnedCell.groupId === pinnedGroupId)
  }, [exportCellMorphotype, pinnedGroupId])

  return (
    <CardContent
      key={`cc${pinnedGroupId}`}
      sx={{ my: '8px', py: '0' }}
      onMouseOver={() =>
        setPinnedGroupHighlighted({
          pinnedGroupId,
          isHighlighted: true,
        })
      }
      onMouseOut={() =>
        setPinnedGroupHighlighted({
          pinnedGroupId,
          isHighlighted: false,
        })
      }
    >
      <Paper variant="outlined" sx={{ p: 1, pb: 1 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ alignItems: 'center' }}>
          <Stack key={`stack${pinnedGroupId}`} direction="row" justifyContent="space-between">
            <FormControlLabel
              disabled={!selectedPointLength}
              checked={selectedPointLength > 0 && active}
              control={
                <Checkbox
                  data-testid="pinnedImagesCheckbox"
                  onChange={(e) =>
                    setPinnedGroupActive({
                      pinnedGroupId,
                      active: e.target.checked,
                    })
                  }
                />
              }
              label={name}
            />
            <ChangeIconButton onClick={onChange} />
            <DeleteIconButton onClick={onDelete} />
            {cellVisualizationsMergeEnabled && (
              <MergeIconButton
                iconButtonProps={{ disabled: !enableMerge }}
                data-testid="mergeButton"
                tooltip="Merge two or more selections"
                onClick={onMerge}
              />
            )}
          </Stack>
        </Stack>
        {activeGroup?.cellIds.length && (
          <Box my="8px">
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                textAlign: 'left',
              }}
            >
              {`${activeGroup?.cellIds.length}/10 selected for export`}
            </Typography>
          </Box>
        )}
        {pinnedMorphotype.active && (
          <>
            <ActionPlotCellImages
              pinnedGroupId={pinnedGroupId}
              cells={points?.slice(startIndex, endIndex)}
            />
            <Stack
              direction="row"
              data-testid={`${pinnedMorphotype.name}_pinnedText`}
              justifyContent="space-between"
            >
              <FiltersAppliedTooltip
                open={filtersAppliedId === pinnedMorphotype.id}
                cellInfoGroups={cellInfoGroupsSnapshot}
                comparisonDimensions={comparisonDimensionsSnapshot}
                onClose={() => setFiltersAppliedId(-1)}
              >
                <IconButton
                  onClick={() => setFiltersAppliedId(pinnedMorphotype.id)}
                  disabled={disableFilterButton}
                >
                  <FilterAltOutlinedIcon />
                </IconButton>
              </FiltersAppliedTooltip>
              <CellImagePagination
                count={selectedPointLength}
                onPaginationChange={handlePaginationChange}
                defaultPage={pinnedMorphotype.pagination?.page}
                defaultItemsPerPage={pinnedMorphotype.pagination?.itemsPerPage}
              />
            </Stack>

            {percentOutputText ? (
              <>
                <hr />
                <Typography sx={{ fontSize: '11.7895px' }}>{percentOutputText}</Typography>
              </>
            ) : null}
          </>
        )}
      </Paper>
    </CardContent>
  )
}

export default PinnedGroupContent
