import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import axios, { CancelTokenSource } from 'axios'
import useSessionApi from 'components/cell-visualizations/useSessionApi'
import { DeepcellDialog, DeepcellPrimarySelect, DeepcellTextField } from 'components/shared'
import useFlagCondition from 'components/shared/useFlagCondition'
import useFlags from 'components/shared/useFlags'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { getSession, Run, SamplingStrategy, VisualizationSessionConfig } from 'utils/api'
import { v4 as uuidv4 } from 'uuid'
import { DeepcellFormControlLabel } from 'components/shared/DeepcellFormControlLabel'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { FieldsToInclude } from './FieldsToInclude'
import {
  DEFAULT_SAMPLE_SIZE,
  estimateCreationTime,
  getAvailableSampleSizeOptions,
  getDefaultOption,
  totalCellCountInRuns,
} from './utils'

interface StartAnalysisDialogProps {
  isOpen: boolean
  handleClose?(): void
  handleStartAnalysis?(): void
  runs: Run[]
  /**
   * When this is populated, pass this to the backend instead of "runs"
   */
  customRunData?: Record<string, unknown>[]
  hideMorphometricsModel?: boolean
  defaultSessionId?: number
}

// @TODO Remove this code once we've removed the ability to set UMAP Names in the API (CBS-695)
export function generateModelName(): string {
  // Generate a UUID
  const buffer = Buffer.alloc(16) // UUIDs are 16 bytes
  uuidv4({}, buffer)

  // Encode the Buffer as base64 and remove any trailing '=' characters
  const base64Url = buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')

  const modelName = `cell_vis_umap_${base64Url}`

  return modelName
}

export const StartAnalysisDlg = (props: StartAnalysisDialogProps): JSX.Element => {
  const {
    handleClose,
    handleStartAnalysis,
    isOpen,
    runs,
    customRunData,
    defaultSessionId = 0,
    hideMorphometricsModel = false,
  } = props

  const theme = useTheme()

  const { createSessionFromRuns } = useSessionApi()
  const { updateSessionAndVersion } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const advancedOptionsEnabled = useFlagCondition(
    'cellVisualizationCreateSessionAdvancedOptionsEnabled'
  )
  const demoEnabled = useFlagCondition('demoEnabled')
  const [sessionId, setSessionId] = useState(defaultSessionId)
  const { showProgress, showError, clearNotification } = useNotificationSlice()

  // Only enable sampling if there's more than one run
  // If there's only one run, then uniform random sampling is always good enough
  const samplingEnabled =
    useFlagCondition('cellVisualizationsEnableSamplingOption') && runs.length > 1
  const eventsManager = useEventsManager()

  const triggerPreFilter = preFilterEnabled && !demoEnabled
  const POLL_WAIT = 2000

  useQuery(['getSession', sessionId], () => getSession(sessionId, 1), {
    enabled: !!sessionId,
    retry: false,
    refetchInterval: POLL_WAIT,
    onError: async (error: Error) => {
      setSessionId(0)
      showError(error.message)
    },
    onSuccess: async (response) => {
      showProgress('Loading...')
      if (response?.data?.data?.status === 'ready') {
        updateSessionAndVersion(sessionId, 1, triggerPreFilter)
        clearNotification()
        if (handleStartAnalysis) {
          handleStartAnalysis()
        }
      }
    },
  })

  const enableSampleSize = useFlagCondition('cellVisualizationsEnableSampleSize')
  const { cellVisualizationsDefaultHFMModelId } = useFlags()

  const runFieldNames = customRunData ? _.keys(customRunData[0]) : undefined
  const [selectedRunFieldNames, setSelectedRunFieldNames] = useState<string[] | undefined>(
    runFieldNames
  )

  const [cancellationSource, setCancellationSource] = useState<CancelTokenSource | undefined>(
    undefined
  )

  const totalCellCount = totalCellCountInRuns(runs)
  const sampleSizeOptions = useMemo(
    () => getAvailableSampleSizeOptions(totalCellCount),
    [totalCellCount]
  )

  const [sampleSize, setSampleSize] = useState<number>(DEFAULT_SAMPLE_SIZE)
  const [morphoModel, setMorphoModel] = useState(cellVisualizationsDefaultHFMModelId)
  const [sessionName, setSessionName] = useState('')

  // By default, for now we sample uniformly from all cells
  //
  // This is the simplest behaviour, and also compatible with Axon releases before 1.0.8
  // And unless there's significant imbalance between runs or samples, the user may not want to
  // bother with the other options (which might have the side effect of reducing the amount of data
  // in the session if you don't have enough cells)
  const [samplingStrategy, setSamplingStrategy] = useState(SamplingStrategy.PROPORTIONAL)

  // Add state for advanced options
  const [leidenResolution, setLeidenResolution] = useState<string>('0.5')
  const [nNeighbors, setNNeighbors] = useState<string>('15')
  const [minDist, setMinDist] = useState<string>('0.1')
  const [spread, setSpread] = useState<string>('1')
  const [advancedOptionsExpanded, setAdvancedOptionsExpanded] = useState<boolean>(false)

  const { cellVisualizationCreationTimeCoefficients } = useFlags()
  const timeEstimate = cellVisualizationCreationTimeCoefficients
    ? estimateCreationTime(totalCellCount, sampleSize, cellVisualizationCreationTimeCoefficients)
    : ''

  /* Each time the runs list changes, change the default option */
  useEffect(() => {
    const defaultOption = getDefaultOption(sampleSizeOptions)
    setSampleSize(defaultOption.value as number)
  }, [runs, sampleSizeOptions])

  // Reset all form fields when dialog closes
  useEffect(() => {
    // Only reset when dialog closes (isOpen changes from true to false)
    if (!isOpen) {
      // Reset form fields to default values
      setSessionName('')
      setSelectedRunFieldNames(runFieldNames)
      setSamplingStrategy(SamplingStrategy.PROPORTIONAL)
      setMorphoModel(cellVisualizationsDefaultHFMModelId)

      // Reset sample size to default
      const defaultOption = getDefaultOption(sampleSizeOptions)
      setSampleSize(defaultOption.value as number)

      // Reset advanced options
      setLeidenResolution('0.5')
      setNNeighbors('15')
      setMinDist('0.1')
      setSpread('1')
      setAdvancedOptionsExpanded(false)
    }
  }, [isOpen, cellVisualizationsDefaultHFMModelId, runFieldNames, sampleSizeOptions])

  const onStartAnalysis = async () => {
    // pick from fields based on fieldNames
    const selectedFields = selectedRunFieldNames
      ? customRunData?.map((field) =>
          selectedRunFieldNames.reduce((acc, fieldName) => {
            const name = fieldName === 'Run Id' ? 'run_id' : fieldName
            return { ...acc, [name]: field[fieldName] }
          }, {} as Record<string, unknown>)
        )
      : customRunData

    const projectionModelName = {}

    const createCancellationSource = axios.CancelToken.source()
    setCancellationSource(createCancellationSource)
    const config: VisualizationSessionConfig = {
      ...projectionModelName,
      runs: selectedFields ?? runs.map(({ run_id }) => ({ run_id })),
      morphometric_model: morphoModel,
      dl_only_projection: true,
      max_cell_count: sampleSize,
      cancelToken: createCancellationSource.token,
      name: sessionName,
      sampling_strategy: samplingStrategy,
      leiden_resolution: parseFloat(leidenResolution),
      projection_params: {
        n_neighbors: parseInt(nNeighbors, 10),
        min_dist: parseFloat(minDist),
        spread: parseFloat(spread),
      },
    }
    eventsManager.sendCreateSesssionRequest(config)
    const result = await createSessionFromRuns(config)
    if (result?.data) {
      setSessionId(Number(result?.data.session_id))
    }
  }

  const sampleSizeProps = {
    label: 'Number of Cells in UMAP',
    tooltip:
      'This determines the number of cells to try to sample from the selected runs, using the chosen sampling method below.',
    value: sampleSize,
    onChange: (e: { target: { value: unknown } }) => setSampleSize(e.target.value as number),
  }

  return (
    <DeepcellDialog
      handleCancel={(reason) => {
        if (handleClose && reason !== 'backdropClick') handleClose()
        if (cancellationSource) cancellationSource.cancel('Cancelled by user')
      }}
      handleConfirm={onStartAnalysis}
      open={isOpen}
      okLabel="Start Analysis"
      okDisabled={!morphoModel || !sessionName}
      titleLabel="Start Analysis"
    >
      <Stack spacing={1.5} sx={{ width: 550, px: 1 }}>
        <Box alignSelf="center" component="p" sx={{ color: theme.palette.info.dark, m: 0 }}>
          {`${runs.length} Run${runs.length === 1 ? '' : 's'} selected`}
        </Box>
        <DeepcellTextField
          label="Session Name"
          placeholder="Session Name"
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
          onFocus={(e) => e.target.select()}
        />
        {customRunData?.length ? (
          <FieldsToInclude
            options={runFieldNames}
            values={selectedRunFieldNames}
            onChange={setSelectedRunFieldNames}
          />
        ) : null}
        {enableSampleSize && (
          <DeepcellPrimarySelect items={sampleSizeOptions} {...sampleSizeProps} />
        )}
        {!hideMorphometricsModel ? (
          <DeepcellPrimarySelect
            items={['hfm_d6:v1', 'hfm_d6_finetuned_morph_v9:v1']}
            value={morphoModel}
            onChange={(e) => setMorphoModel(`${e.target.value}`)}
            label="Morphometrics Model"
          />
        ) : null}
        {samplingEnabled && (
          <Stack>
            <FormControl>
              <FormLabel>Cell Sampling Method</FormLabel>
              <RadioGroup
                row
                value={samplingStrategy}
                onChange={(e) => setSamplingStrategy(e.target.value as SamplingStrategy)}
              >
                <DeepcellFormControlLabel
                  value={SamplingStrategy.PROPORTIONAL}
                  control={<Radio />}
                  label="Random sampling"
                  tooltip="Sample cells randomly from all runs, with larger runs contributing proportionally more cells"
                />
                <DeepcellFormControlLabel
                  value={SamplingStrategy.EQUAL_BY_RUN}
                  control={<Radio />}
                  label="Equally by run"
                  tooltip="Sample an equal number of cells from each run. If we can't reach the target “# of Cells in UMAP,” we will use an equal number of cells from each run while trying to use as many cells as possible."
                />
                <DeepcellFormControlLabel
                  value={SamplingStrategy.EQUAL_BY_SAMPLE}
                  control={<Radio />}
                  label="Equally by sample ID"
                  tooltip="Sample an equal number of cells from each Sample ID. If we can’t reach the target “# of Cells in UMAP,” we will use an equal number of cells from each Sample ID, while trying to use as many cells as possible."
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        )}

        {/* Advanced Options Section - Moved up and made more compact */}
        {advancedOptionsEnabled && (
          <Accordion
            expanded={advancedOptionsExpanded}
            onChange={() => setAdvancedOptionsExpanded(!advancedOptionsExpanded)}
            sx={{ boxShadow: 'none', '&:before': { display: 'none' }, mt: 0 }}
          >
            <AccordionSummary
              expandIcon={
                advancedOptionsExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
              }
              sx={{
                padding: '0px',
                minHeight: '36px',
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper': {
                  marginRight: 1,
                },
                '& .MuiAccordionSummary-content': {
                  marginLeft: 0,
                },
              }}
            >
              <Typography sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                Advanced Options
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px 0px', pl: 4 }}>
              <Stack spacing={1} sx={{ mt: -0.5 }}>
                {/* Leiden Resolution with tooltip */}
                <DeepcellTextField
                  label="Leiden Clustering Resolution"
                  tooltip="Lower values create fewer clusters, and higher values create more clusters (default: 0.5)"
                  type="number"
                  value={leidenResolution}
                  onChange={(e) => setLeidenResolution(e.target.value)}
                  inputProps={{ min: 0 }}
                  size="small"
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                />

                {/* UMAP Parameters section */}
                <Box sx={{ mt: 0 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500, fontSize: '0.875rem', mb: 0.25 }}
                  >
                    UMAP Parameters
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1.5 }}>
                    {/* Neighbors parameter */}
                    <DeepcellTextField
                      label="# of Neighbors"
                      tooltip="Use a lower number to focus more on local structure, and higher number to focus more on global structure (default: 15)"
                      type="number"
                      value={nNeighbors}
                      onChange={(e) => {
                        // Ensure integer value
                        const value = parseInt(e.target.value, 10)
                        if (!Number.isNaN(value)) {
                          setNNeighbors(String(value))
                        } else {
                          setNNeighbors(e.target.value)
                        }
                      }}
                      inputProps={{ min: 1 }}
                      size="small"
                      margin="dense"
                      sx={{ flex: 1 }}
                      onFocus={(e) => e.target.select()}
                    />

                    {/* Min Dist parameter */}
                    <DeepcellTextField
                      label="Minimum Distance"
                      tooltip="Minimum distance between points in the layout. Smaller values will show finer structures and clustering, and bigger values will preserve more broad structure (default: 0.1)"
                      type="number"
                      value={minDist}
                      onChange={(e) => setMinDist(e.target.value)}
                      inputProps={{ min: 0, step: 0.1 }}
                      size="small"
                      margin="dense"
                      sx={{ flex: 1 }}
                      onFocus={(e) => e.target.select()}
                    />

                    {/* Spread parameter */}
                    <DeepcellTextField
                      label="Spread"
                      tooltip="Increase spread to increase global scaling (default: 1)"
                      type="number"
                      value={spread}
                      onChange={(e) => setSpread(e.target.value)}
                      inputProps={{ min: 0, step: 0.5 }}
                      size="small"
                      margin="dense"
                      sx={{ flex: 1 }}
                      onFocus={(e) => e.target.select()}
                    />
                  </Box>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}

        {timeEstimate ? (
          <Box alignSelf="left" component="p" sx={{ color: theme.palette.info.dark, my: 0.5 }}>
            Estimated time to start analysis: {timeEstimate}
          </Box>
        ) : null}
      </Stack>
    </DeepcellDialog>
  )
}

export default StartAnalysisDlg
