import * as ENVIRONMENTS from 'constants/environments';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';


import FIREBASE_CONFIG_DEV, { AUTH0_CONFIG_CLOUD_DEV } from './dev';

// Set this to true and uncomment the following block to enable mock APIs for testing
// Please remember before merging PRs to comment out the block again
// This avoids including Webpack including mock API code in the production build
export const ENABLE_MOCK_API = false
// if (ENABLE_MOCK_API) {
//   import('../mocks/browser')
//     .then(({ worker }) => worker.start())
// }

// Set this to true to use staging instead of local API for local development
export const ENABLE_STAGING_API_FOR_LOCAL_DEV = true

// @TODO: Need to find the method to fetch license from account of AgGrid itself.
export const AG_GRID_LICENSE_KEY =
  'CompanyName=Deepcell, Inc,LicensedApplication=Deepcell Cloud,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-029302,SupportServicesEnd=15_June_2023_[v2]_MTY4Njc4MzYwMDAwMA==e5617e876ece97b6b97a54d82837a58f'

const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyAHY81w7cAbY3ehAjrdhGZb92mhgAV3vBo',
  authDomain: 'dc-classifier.firebaseapp.com',
  databaseURL: 'https://dc-classifier.firebaseio.com',
  projectId: 'dc-classifier',
  storageBucket: 'dc-classifier.appspot.com',
  messagingSenderId: '1060407151248',
  appId: '1:1060407151248:web:85e72d291ca9de43736615',
  measurementId: 'G-EW3PJ1WBPD',
}

const FIREBASE_CONFIG_STAGING_LOCAL_DEV = {
  apiKey: 'AIzaSyAARxOjl8ejFHJ7zZHohzpugtR9VO8jolg',
  authDomain: 'monocle-staging-a7d13.firebaseapp.com',
  databaseURL: 'https://monocle-staging-a7d13.firebaseio.com',
  projectId: 'monocle-staging-a7d13',
  storageBucket: 'monocle-staging-a7d13.appspot.com',
  messagingSenderId: '158057788799',
  appId: '1:158057788799:web:e14269841f6365aad7964a',
  measurementId: 'G-E5MYH83SE5',
}

const FIREBASE_CONFIG_EXTERNAL_DEMO = {
  apiKey: 'AIzaSyCXHSiwZuWSvJtdsY0MyIpVOc730Zo-eUo',
  authDomain: 'external-demo-37a1c.firebaseapp.com',
  databaseURL: 'https://external-demo-37a1c.firebaseio.com',
  projectId: 'external-demo-37a1c',
  storageBucket: 'external-demo-37a1c.appspot.com',
  messagingSenderId: '782466927598',
  appId: '1:782466927598:web:807853ec175a692c9c1cff',
  measurementId: 'G-JFXYLRXS3F',
}

export const isProduction = process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION
export const isStaging = process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING
export const isExternalDemo = process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO
export const isCloudDev =
  process.env.REACT_APP_ENV && !isProduction && !isStaging && !isExternalDemo

function getFirebaseConfig() {
  if (isProduction) return FIREBASE_CONFIG_PROD
  if (isStaging) return FIREBASE_CONFIG_STAGING_LOCAL_DEV
  if (isExternalDemo) return FIREBASE_CONFIG_EXTERNAL_DEMO
  if (isCloudDev) return FIREBASE_CONFIG_DEV
  return FIREBASE_CONFIG_STAGING_LOCAL_DEV
}

const firebaseConfig = getFirebaseConfig()

export type Auth0ConfigParameters = {
  domain: string
  clientId: string
}

// Auth0 Configuration Parameters from the Deepcell Cloud Single Page App in each Auth0 tenant
const AUTH0_CONFIG_PROD: Auth0ConfigParameters = {
  domain: 'deepcell.us.auth0.com',
  clientId: 'JmcXeHMU1MPY9MieOYy51awbEx2CGQu8',
}

const AUTH0_CONFIG_STAGING: Auth0ConfigParameters = {
  domain: 'deepcell-staging.us.auth0.com',
  clientId: 'Z92a6z4SNYWF341lXK2oizHIA1O5skHY',
}

const AUTH0_CONFIG_EXTERNAL_DEMO: Auth0ConfigParameters = {
  domain: 'deepcell-demo.us.auth0.com',
  clientId: 'qZrw7JhmtelSBoCnBVMaw8GXc8adLjB2',
}

let AUTH0_CONFIG_LOCAL_DEV: Auth0ConfigParameters = {
  domain: 'deepcell-dev.us.auth0.com',
  clientId: '0tjOdWXvmIjvZcZYPlto7564TuALG9Yo',
}

if (ENABLE_STAGING_API_FOR_LOCAL_DEV) {
  AUTH0_CONFIG_LOCAL_DEV = AUTH0_CONFIG_STAGING
}

// Switch to this if you want to use a local API.  And also update api.ts
// const AUTH0_CONFIG_LOCAL_DEV: Auth0ConfigParameters = {
//     domain: 'deepcell-dev.us.auth0.com',
//     clientId: '0tjOdWXvmIjvZcZYPlto7564TuALG9Yo',
// }

function getAuth0Config() {
  if (isProduction) return AUTH0_CONFIG_PROD
  if (isStaging) return AUTH0_CONFIG_STAGING
  if (isExternalDemo) return AUTH0_CONFIG_EXTERNAL_DEMO
  if (isCloudDev) return AUTH0_CONFIG_CLOUD_DEV
  return AUTH0_CONFIG_LOCAL_DEV
}

export const auth0Config = getAuth0Config()

const app=initializeApp(firebaseConfig)
getAnalytics(app)
getPerformance(app)

export default {
  projectId: firebaseConfig.projectId,
}

// Initialize singleton Firebase feature flag functionality (aka "Remote Config")
const remoteConfig = getRemoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = isProduction ? 3600000 : 10000

// yes: true for all users
// internal: true for deepcell users
// no: false for all users
export type AccessLevelSetting = 'yes' | 'internal' | 'no'
export type YesNoSetting = 'yes' | 'no'

export type RemoteConfigSettings = {
  demoEnabled: AccessLevelSetting
  runsStartAnalysisEnabled: AccessLevelSetting
  downloadEmbeddingsEnabled: AccessLevelSetting
  cellVisualizationsColorPickerEnabled: AccessLevelSetting
  // Contains coefficients a,b,c,d in a comma-separated string
  // for the equation a * total_cell_count + b + c * sample_size + d * sample_size^2 = estimated creation time
  cellVisualizationCreationTimeCoefficients: string
  cellVisualizationsEnableSampleSize: AccessLevelSetting
  // Color scale to use for plotting continous data fields (e.g. area)
  cellVisualizationsContinousColorScale: string
  cellVisualizationsReverseContinuousColorScale: YesNoSetting
  // Color scale to use for plotting density plot
  cellVisualizationsDensityColorScale: string
  cellVisualizationsReverseDensityColorScale: YesNoSetting
  cellVisualizationsGuideEnabled: AccessLevelSetting
  // The default HFM model id to use (in format  "<name>:v<version>" like "hfm_d6:v1")
  cellVisualizationsDefaultHFMModelId: string
  // String containing one or more invalid characters in model names
  cellVisualizationsModelNameInvalidCharacters: string
  // Whether to enable merging of morphotypes
  cellVisualizationsMergeEnabled: AccessLevelSetting
  // What's the fraction of total area threshold to start rejecting self-intersecting polygon regions at?
  cellVisualizationSelfIntersectingPolygonMaxAreaThreshold: number
  // Enable differential features comparisons
  cellVisualizationDifferentialFeaturesEnabled: AccessLevelSetting
  // Enable the option to show plots for individual differential features top features
  cellVisualizationDifferentialFeaturesIndividualPlotsEnabled: AccessLevelSetting
  // Enable the option to show advanced session creation parameters
  cellVisualizationCreateSessionAdvancedOptionsEnabled: AccessLevelSetting,
  // How many top features to show in the top features panel (showing multiple distribution plots)
  validModelList: string
  modelsV3ApiEnabled: AccessLevelSetting
  manualQualityReviewEnabled: AccessLevelSetting
  showSaveAs: AccessLevelSetting
  allowSessionDelete: AccessLevelSetting
  enableDensityPlot: AccessLevelSetting
  densityComparisonEnabled: AccessLevelSetting
  showUploadRunDataTSV: AccessLevelSetting
  cellVisualizationsPrefilterEnabled: AccessLevelSetting
  downtime: string
  cellVisualizationsEnableSamplingOption: AccessLevelSetting
}

export const DEFAULT_REMOTE_CONFIG: RemoteConfigSettings = {
  demoEnabled: 'no',
  runsStartAnalysisEnabled: 'yes',
  downloadEmbeddingsEnabled: 'yes',
  cellVisualizationsColorPickerEnabled: 'no',
  // Contains coefficients a,b,c,d in a comma-separated string
  // for the equation a * total_cell_count + b + c * sample_size + d * sample_size^2 = estimated creation time
  cellVisualizationCreationTimeCoefficients: '1e-4,10.409,1.07e-3,1.07e-8',
  cellVisualizationsEnableSampleSize: 'no',
  // Color scale to use for plotting continous data fields (e.g. area)
  cellVisualizationsContinousColorScale: 'YlOrRd',
  cellVisualizationsReverseContinuousColorScale: 'yes',
  // Color scale to use for plotting density plot
  cellVisualizationsDensityColorScale: 'YlOrRd',
  cellVisualizationsReverseDensityColorScale: 'yes',
  cellVisualizationsGuideEnabled: 'yes',
  // The default HFM model to use.  Added in for SWT-246 as part of Morph v9 transition
  cellVisualizationsDefaultHFMModelId: 'hfm_d6:v1',
  // String containing one or more invalid characters in model names
  // Currently, by default only disallow ':', which is used internally as a separator
  // between model name and version e.g. hfm_d6:v1
  cellVisualizationsModelNameInvalidCharacters: ':',
  // Whether to enable merging of morphotypes
  cellVisualizationsMergeEnabled: 'internal',
  // What's the fraction of total area threshold to start rejecting self-intersecting polygon regions at?
  cellVisualizationSelfIntersectingPolygonMaxAreaThreshold: 0.01,
  // Enable differential features comparisons
  cellVisualizationDifferentialFeaturesEnabled: 'no',
  // Enable the option to show plots for individual differential features top features
  cellVisualizationDifferentialFeaturesIndividualPlotsEnabled: 'no',
  // Enable the option to show advanced session creation parameters
  cellVisualizationCreateSessionAdvancedOptionsEnabled: 'no',
  // How many top features to show in the top features panel (showing multiple distribution plots)
  validModelList: '[]',
  modelsV3ApiEnabled: 'no',
  manualQualityReviewEnabled: 'no',
  showSaveAs: 'yes',
  allowSessionDelete: 'no',
  enableDensityPlot: 'yes',
  densityComparisonEnabled: 'yes',
  showUploadRunDataTSV: 'yes',
  cellVisualizationsPrefilterEnabled: 'yes',
  downtime: '',
  cellVisualizationsEnableSamplingOption:'yes'
}

export const APM_CONFIG = {
  datadog: {
    URL: 'datadoghq.com',
    applicationId: '9b3823e3-d1bd-4d02-9303-85e7aeeeceaa',
    clientToken: 'pub60b4747b326d1dace4b2b8c9ea854c11',
  },
  origins: [
    'localhost',
    'cloud-staging.deepcell.com',
    'cloud.deepcell.com',
    'demo.deepcell.com',
    /^\//,
  ],
  serviceName: 'Deepcell Cloud',
}
