import { FormControlLabel, FormControlLabelProps, Tooltip, IconButton, Box } from '@mui/material'
import React from 'react'
import InfoIcon from '@mui/icons-material/Info'

export interface DeepcellFormControlLabelProps extends Omit<FormControlLabelProps, 'label'> {
  label: React.ReactNode
  tooltip?: string
}

export const DeepcellFormControlLabel = ({
  label,
  tooltip,
  ...rest
}: DeepcellFormControlLabelProps): JSX.Element => {
  if (tooltip) {
    // Create a combined label with the original label and the tooltip icon
    const combinedLabel = (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {label}
        <Tooltip title={tooltip} arrow placement="top">
          <IconButton size="small" sx={{ ml: 0.1, p: 0, height: '16px', width: '16px' }}>
            <InfoIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      </Box>
    )

    return <FormControlLabel label={combinedLabel} {...rest} />
  }

  return <FormControlLabel label={label} {...rest} />
}

export default DeepcellFormControlLabel
