import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { ALL_OTHER_CELLS_ID, CellPlotDatum, PinnedMorphotype } from 'redux/slices'

/**
 * Computes a set of Cell ID values from a list of morphotypeIds.
 *
 * This gets used by the CreateComparisonForm
 * And also when plotting results in DistributionComparisonPlot
 *
 * @param morphotypeIds The list of morphotype IDs to convert to cell IDs
 * @param mergedPinnedCells The list of merged pinned cells to use to look up morphotypes and their cells
 * */

export function getCellIdSetFromMorphotypeIds(
  morphotypeIds: number[],
  pinnedCells?: PinnedMorphotype[]
): Set<string> {
  const cellIds: Set<string> = new Set()

  morphotypeIds.forEach((morphotypeId) => {
    if (morphotypeId === ALL_OTHER_CELLS_ID) {
      throw Error('ALL_OTHER_CELLS_ID not supported in this context')
    } else {
      pinnedCells?.forEach((morphotype) => {
        if (morphotype.id === morphotypeId) {
          morphotype.cells?.points?.forEach((cell: CellPlotDatum) => {
            if (cell.cellId) {
              cellIds.add(cell.cellId)
            }
          })
        }
      })
    }
  })
  return cellIds
}
/** Implements set difference (not available in Node 21 or our Typescript linter yet) */
function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const differenceSet = new Set<T>(setA)
  setB.forEach((elem) => {
    differenceSet.delete(elem)
  })
  return differenceSet
}
/**
 * Computes a list of Cell ID values corresponding to a selection
 *
 * This gets used by the CreateComparisonForm
 * And also when plotting results in DistributionComparisonPlot
 *
 * @param morphotypeIds The list of morphotype IDs to convert to cell IDs
 * @param otherMorphotypeIds The list of morphotype IDs to exclude from the conversion
 * @param cellInfo The list of all cells to use to compute "All Other Cells" when morphotypeIds contains ALL_OTHER_CELLS
 * @param pinnedCells The list of (unmerged) pinned cells to use to look up morphotypes and their cells
 *
 * @returns A list of cell IDs corresponding to the input morphotype IDs
 */

export function convertMorphotypeIdsToCellIds(
  morphotypeIds: number[],
  otherMorphotypeIds: number[],
  cellInfo: CellInfo[],
  pinnedCells?: PinnedMorphotype[]
): string[] {
  if (morphotypeIds.includes(ALL_OTHER_CELLS_ID)) {
    // ALL_OTHER_CELLS_ID should be the only element in this list
    const allCellIds = new Set(cellInfo.map((cell) => cell.CELL_ID))
    const otherCellIds = getCellIdSetFromMorphotypeIds(otherMorphotypeIds, pinnedCells)
    return Array.from(difference(allCellIds, otherCellIds))
  }

  const morphotypeCellIds = getCellIdSetFromMorphotypeIds(morphotypeIds, pinnedCells)
  return Array.from(morphotypeCellIds)
}
