import { Stack } from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import { useNotificationSlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { SelectableButton, SelectableButtonProps } from './SelectableButton'

export const ScatterDensitySwitchButtons = (): JSX.Element => {
  const { showProgress, clearNotification } = useNotificationSlice()
  const {
    setPlotDisplayType,
    cellVisualizations: { plotDisplayType },
  } = useCellVisualizationsSlice()

  const [isSwitchingToDensity, setIsSwitchingToDensity] = useState(false)


  const onClick = (newPlotDisplayType: typeof plotDisplayType) => () => {
    let wait = 0
    // Show progress bar when switching from scatterplot to density
    // (which sometimes can take a few seconds to generate density data)
    if (plotDisplayType === 'scatter' && newPlotDisplayType === 'density') {
      showProgress('Switching to Density Plot')
      setIsSwitchingToDensity(true)
      wait = 200
    }

    /*
     * When switching from scatter plot to density plot there can be long delay while the density map is being generated
     * Setting this timeout guarantees that the user will see the progress bar above
     */
    const timeoutId = setTimeout(() => {
      setPlotDisplayType(newPlotDisplayType)
      clearTimeout(timeoutId)
    }, wait)
  }

  // Detect when rendering is complete, and hide the progress bar a little bit after that
  // The expensive code to update the density plot data happens during the rendering phase
  // So useLayoutEffect (which runs after rendering) will wait until the density computation's done
  // before clearing the notification
  useLayoutEffect(() => {
    if (isSwitchingToDensity) {
      // Ensure progress shows another 500ms after the rendering is complete
      const wait = 500

      setTimeout(() => {
        clearNotification()
        setIsSwitchingToDensity(false)
      }, wait)
    }
  }, [isSwitchingToDensity, clearNotification])


  const buttonProps: SelectableButtonProps = {
    sx: { height: '34px', width: '135px' },
    unselectedVariant: 'text',
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        height: '44px',
        width: '305px',
        border: (t) => `1px solid ${t.palette.primary.main}`,
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <SelectableButton
        {...buttonProps}
        selected={plotDisplayType === 'scatter'}
        onClick={onClick('scatter')}
      >
        Scatter Plot
      </SelectableButton>
      <SelectableButton
        {...buttonProps}
        selected={plotDisplayType === 'density'}
        onClick={onClick('density')}
      >
        Density Plot
      </SelectableButton>
    </Stack>
  )
}

export default ScatterDensitySwitchButtons
