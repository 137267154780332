import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton, Tooltip, styled } from '@mui/material'

interface DeepcellLabelProps {
  label: string
  id?: string
  tooltip?: string
}

const StyledLabel = styled('div')(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '13px',
  fontFamily: theme.typography.fontFamily,
  color: '#000000',
  margin: '8px 0px',
}))

export const DeepcellLabel = ({ label, id, tooltip, ...rest }: DeepcellLabelProps): JSX.Element => {
  return (
    <StyledLabel id={id || ''} {...rest}>
      {tooltip ? (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '-8px', mb: '-8px' }}>
          {label}
          <Tooltip title={tooltip} arrow placement="top">
            <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
              <InfoIcon fontSize="small" color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        label
      )}
    </StyledLabel>
  )
}

export default DeepcellLabel
