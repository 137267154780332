import { AlertColor } from '@mui/material'
import { useMemo } from 'react'
import { NotificationComponent, NotificationSlice } from '../NotificationSlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
  const methodsAndData = useSliceWrapper('notification', NotificationSlice)
  const { displayNotification } = methodsAndData

  // Use useMemo to ensure that the helper methods are stable and do not trigger unnecessary re-renders
  const extraMethods = useMemo(() => {
    return {
      showProgress: (message: string) => {
        displayNotification({
          message,
          showProgress: true,
          type: 'info',
        })
      },

      showSuccess: (message: string, timeout = 5000) => {
        displayNotification({
          message,
          type: 'success',
          timeout,
        })
      },

      showError: (
        message: string,
        component?: NotificationComponent,
        severity: AlertColor = 'error'
      ) => {
        displayNotification({
          message,
          type: severity,
          component,
        })
      },
    }
  }, [displayNotification])
  return { ...methodsAndData, ...extraMethods }
}

export const useNotificationSlice = (): ReturnType<typeof useGetMethodsAndData> =>
  useGetMethodsAndData()

export default useNotificationSlice
