import * as ROUTES from 'constants/routes'
import { Box, Stack } from '@mui/material'
import { GridApi } from 'ag-grid-community'
import useFlagCondition from 'components/shared/useFlagCondition'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import useRunsSlice from 'redux/slices/hooks/useRunsSlice'
import { Run, getRuns } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import moment from 'moment'
import useDeepcellError, { ReasonCode } from 'components/shared/useDeepcellError'
import StartAnalysisDlg from '../cell-visualizations/startAnalysis/StartAnalysisDlg'
import BasicInfoTable from './BasicInfoTable'
import QCMetricsGrid from './QCMetricsGrid'
import WellResultsGrid from './WellResultsGrid'
import SectionCard from '../shared/SectionCard'
import RunDetailsActionBar from './RunDetailsActionBar'
import CellTypeGrid from './CellTypeGrid'
import QCStatusGrid from './QCStatusGrid'
import useRunQueryParams from './useRunQueryParams'
import ImagesSection from './ImagesSection'

export const CLASS_DISTRIBUTION_URL =
  'https://app.mode.com/deepcell/reports/7b023439388c?param_run_id_param='
export const LABEL_SUMMARY_URL =
  'https://app.mode.com/deepcell/reports/3565fff6cd73?param_run_id_param='
export const OOF_SCORE_URL = 'https://app.mode.com/deepcell/reports/313d3d2fda59?param_RunId='

interface Props {
  showDetailsPanel: (value: boolean) => void
  onDownload: () => void
  selectedRunIds?: string[]
  setSelectedRunIds: Dispatch<SetStateAction<string[]>>
}

function RunDetailsPanel(props: Props): JSX.Element {
  const { isInternalUser, permissions } = useAuthTokens()

  const [generateAnalysisIsOpen, setGenerateAnalysisIsOpen] = useState(false)
  const { onDownload, selectedRunIds, setSelectedRunIds, showDetailsPanel } = props
  const queryClient = useQueryClient()
  const [runs, setRuns] = useState<Run[]>([])
  const [gridApi] = useState<GridApi | null>(null)
  const { runs: runsFromState, set } = useRunsSlice()
  const manualQualityReviewEnabled = useFlagCondition('manualQualityReviewEnabled')
  const { showError } = useDeepcellError()

  const { query, setQuery } = useRunQueryParams()

  const loadInitialRuns = useCallback(async () => {
    // if every selected run is already stored in state, then just use state, otherwise fetch the latest data
    const selectedRunsInState = runsFromState.filter((r) => selectedRunIds?.includes(r.run_id))
    const allSelectedRunIdsAreInState = selectedRunsInState.length === selectedRunIds?.length

    if (allSelectedRunIdsAreInState) {
      setRuns(selectedRunsInState)
    } else {
      const resultRuns = await queryClient.fetchQuery(
        [
          'getRuns',
          {
            run_ids: selectedRunIds,
            limit: selectedRunIds?.length || 1,
          },
        ],
        getRuns,
        {
          staleTime: 0,
        }
      )
      const updatedRuns = resultRuns.map((run) => {
        const existingRun = selectedRunsInState.find((r) => r.run_id === run.run_id)
        return existingRun || run
      })
      setRuns(updatedRuns)
    }
  }, [queryClient, runsFromState, selectedRunIds])

  useEffect(() => {
    loadInitialRuns()
  }, [loadInitialRuns, gridApi, selectedRunIds])

  const runIdString = selectedRunIds?.join(',')

  function handleBrowseCells() {
    if (runs.length > 1) {
      showError('MULTIPLE_RUNS_NOT_SUPPORTED' as ReasonCode, {
        message: 'Multiple runs are not supported for cell browsing.',
        component: 'ToasterComponent',
      })
      return
    }
    window.open(`${ROUTES.CELL_BROWSING}?sortOrderKey=RUN_ID_ASC&runIds=${runIdString}`, '_blank')
  }

  function replaceRun(newRun: Run, rns: Run[]) {
    const index = rns.findIndex((r) => r.run_id === newRun.run_id)
    if (index > -1) rns.splice(index, 1, newRun)
  }

  function updateLocalRuns(editedRuns: Run[]) {
    const updatedRuns = [...runs]
    editedRuns.forEach((r) => replaceRun(r, updatedRuns))
    setRuns(updatedRuns)
    const updatedStateRuns = [...runsFromState]
    editedRuns.forEach((r) => replaceRun(r, updatedStateRuns))
    set(updatedStateRuns)
  }

  const handleStartAnalysis = () => {
    setGenerateAnalysisIsOpen(true)
  }

  const handleCloseAction = () => {
    setQuery({ ...query, runIds: [] })
    setSelectedRunIds([])
    showDetailsPanel(false)
  }

  const singleRun = runs.length === 1

  return (
    <Stack gap="15px">
      <SectionCard paddingCSS="0px" title="Run Details" closeAction={handleCloseAction}>
        <Box sx={{ px: '14px', py: '10px' }}>
          {!singleRun && (
            <RunDetailsActionBar
              handleBrowseCells={handleBrowseCells}
              handleStartAnalysis={handleStartAnalysis}
              onDownload={onDownload}
              runIdString={runIdString}
              runs={runs}
            />
          )}
          {singleRun && (
            <RunDetailsActionBar
              handleBrowseCells={handleBrowseCells}
              handleStartAnalysis={handleStartAnalysis}
              onDownload={onDownload}
              runIdString={runIdString}
              instrumentIdString={runs[0].instrument_serial_number}
              runStartTime={moment(runs[0].start_time).valueOf()}
              runEndTime={runs[0].stop_time ? moment(runs[0].stop_time).valueOf() : undefined}
              runStatus={runs[0].stop_reason}
              runs={runs}
            />
          )}
        </Box>
        <BasicInfoTable onEdit={(r) => updateLocalRuns(r)} runs={runs} />
      </SectionCard>

      {singleRun &&
        runs[0].well_sorting_configurations &&
        runs[0].well_sorting_configurations.length > 0 && (
          <SectionCard paddingCSS="0px" title="Sort Results By Well">
            <WellResultsGrid run={runs[0]} />
          </SectionCard>
        )}

      {singleRun && runs[0].cell_counts && runs[0].cell_counts.length > 0 && (
        <SectionCard paddingCSS="0px" title="Cell Count By Morphotypes">
          <CellTypeGrid run={runs[0]} />
        </SectionCard>
      )}

      {singleRun &&
        manualQualityReviewEnabled &&
        (permissions.has('qc:edit') || isInternalUser) && (
          <SectionCard paddingCSS="0px" title="Run Quality Review">
            <Stack direction="column" spacing={1} style={{ width: '100%' }}>
              {permissions.has('qc:edit') && (
                <QCMetricsGrid run={runs[0]} onEdit={(r) => updateLocalRuns(r)} />
              )}
              {isInternalUser && <QCStatusGrid run={runs[0]} />}
            </Stack>
          </SectionCard>
        )}

      {singleRun && <ImagesSection />}

      <StartAnalysisDlg
        isOpen={generateAnalysisIsOpen}
        handleClose={() => setGenerateAnalysisIsOpen(false)}
        runs={runs}
        hideMorphometricsModel
      />
    </Stack>
  )
}

export default React.memo(RunDetailsPanel)
