import {
  CellPlotData,
  ScatterPlotData,
  ScatterPlotMetadata,
} from 'redux/slices/types'
import { CellInfo, CellInfoWithFilterFlag } from '../tsv/types'
import { Combination } from './generateCellInfoCombinations'
import { addNewPlotToData, addToExistingPlot, getCellId } from './utils'
import { BasePlotParams } from './types'

type GetScatterPlotDataFromCellInfoParams = {
  scatterPlotMetaData: ScatterPlotMetadata
  cellInfo: CellInfo[]
  getDataForCell: (cell: CellInfo) => {
    name?: string
    color?: string
    isHidden: boolean
    isRemoved: boolean
    cell: CellInfo
    splitOutput?: Combination[]
  }
  getBasePlot: (params: BasePlotParams) => CellPlotData
  numOfColumns?: number
}

export const getScatterPlotDataFromCellInfo = (
  params: GetScatterPlotDataFromCellInfoParams
): Omit<ScatterPlotData, 'scatterPlotMetaData' | 'scatterPlotMetaDataFiltered'> => {
  const { scatterPlotMetaData, cellInfo, getDataForCell, getBasePlot, numOfColumns = 5 } = params

  const plotData: CellPlotData[] = []

  // all non-removed cells. Might contain hidden cells.
  const outputCellInfo: CellInfoWithFilterFlag[] = []

  const { minX, minY, maxX, maxY } = scatterPlotMetaData
  const xOffset = (maxX - minX) * 1.5
  const yOffset = (maxY - minY) * 1.5

  const cellDataCount = cellInfo.length
  for (let i = 0; i < cellDataCount; i += 1) {
    const cell = cellInfo[i]
    const cellId = getCellId(cell)
    const x = +cell.UMAP_0
    const y = +cell.UMAP_1

    const { name, color, isHidden, isRemoved, splitOutput } = getDataForCell(cell)

    if (isHidden) {
      if (!isRemoved) {
        outputCellInfo.push({...cell, isFiltered: true}) // Mark as filtered but not removed
      }
    } else {
      outputCellInfo.push({...cell, isFiltered: false}) // Not filtered

      const cellIsDefined = name !== undefined && color !== undefined

      if (cellIsDefined) {
        if (splitOutput?.length) {
          splitOutput.forEach((so, soIndex) => {
            const col = soIndex % numOfColumns
            const row = Math.floor(soIndex / numOfColumns)
            const { key, exists } = so

            if (exists) {
              const existingPlot = plotData.find((d) => d.name === key)

              if (existingPlot) {
                addToExistingPlot(
                  existingPlot,
                  x + xOffset * col,
                  y - yOffset * row,
                  x,
                  y,
                  cellId,
                  color
                )
              } else {
                addNewPlotToData(plotData, getBasePlot, {
                  x: x + xOffset * col,
                  y: y - yOffset * row,
                  originalX: x,
                  originalY: y,
                  name: key,
                  cellId,
                  color,
                })
              }
            }
          })
        } else {
          const existingPlot = plotData.find((d) => d.name === name)

          if (existingPlot) {
            addToExistingPlot(existingPlot, x, y, x, y, cellId, color)
          } else {
            addNewPlotToData(plotData, getBasePlot, {
              x,
              y,
              originalX: x,
              originalY: y,
              name,
              cellId,
              color,
            })
          }
        }
      }
    }
  }

  return {
    plotData,
    cellInfo: outputCellInfo,
  }
}

export default getScatterPlotDataFromCellInfo
