import _ from 'lodash'
import { useMemo } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CATEGORICAL_COLOR_PALETTE } from '../shared'
import { CellInfo } from '../tsv/types'
import generateCellInfoCombinations, { Combination } from './generateCellInfoCombinations'

// Define the return type of useCellDataLogic
type GetCellDataFunction = (cell: CellInfo) => {
  name?: string
  color?: string
  isHidden: boolean
  isRemoved: boolean
  splitOutput?: Combination[]
  cell: CellInfo
}

type UseCellDataReturnType = {
  getDataForCell: GetCellDataFunction
  getHiddenAttributeName: (cell: CellInfo) => string
}



const useCellDataLogic = (): UseCellDataReturnType => {
  const {
    cellVisualizations: { selectedCellInfoGroupName, cellInfoGroups },
    prefilteredCellIdSet,
    hiddenCellInfoGroupValues,
    visibleSelectedComparisonDimensions,
  } = useCellVisualizationsSlice()

  // Memoize the return, so that components that depend on this don't re-render if the function isn't changing
  return useMemo(() => {
    const { isContinuous } = cellInfoGroups[selectedCellInfoGroupName] ?? {}

    const hiddenAttributeNames = _.keys(hiddenCellInfoGroupValues ?? {}) as (keyof typeof hiddenCellInfoGroupValues)[]

      /**
       * A single cell could be part of multiple hidden attributes. Return the first one.
       */
      const getHiddenAttributeName = (cell: CellInfo) => {
        let hiddenAttributeName = ''
        for (let i = 0; i < hiddenAttributeNames.length; i += 1) {
          const attrName = hiddenAttributeNames[i]
          if (attrName) {
            const existingHiddenVal = hiddenCellInfoGroupValues[attrName]?.find(
              (c) => c === cell[attrName]
            )
            if (existingHiddenVal) {
              hiddenAttributeName = `${attrName}${existingHiddenVal}`
              i = hiddenAttributeNames.length + 1
            }
          }
        }

        return hiddenAttributeName
      }

      const getDataForCell = (cell: CellInfo) => {
        /**
         * A cell can be toggled as removed/not removed in pre-filtering step
         * A cell can be toggled as hidden/shown in the standard cell vis step
         * */
        const isRemoved = prefilteredCellIdSet.has(cell.CELL_ID)
        const isHidden = isRemoved || Boolean(getHiddenAttributeName(cell))

        let name
        let splitOutput
        let color

        if (!isHidden) {
          splitOutput = visibleSelectedComparisonDimensions
            ? generateCellInfoCombinations(cell, visibleSelectedComparisonDimensions)
            : []

          const selectedCellValue = selectedCellInfoGroupName ? cell[selectedCellInfoGroupName] : ''

          name = isContinuous ? '' : `${selectedCellInfoGroupName}${selectedCellValue}`

          const { color: defaultColor } =
            cellInfoGroups[selectedCellInfoGroupName]?.data.find(
              (c) => c.value === selectedCellValue
            ) ?? {}

          color = isContinuous ? selectedCellValue ?? '' : defaultColor ?? CATEGORICAL_COLOR_PALETTE[0]
        }

        return { name, color, isHidden, isRemoved, splitOutput, cell }
      }

    return { getDataForCell, getHiddenAttributeName }
  }, [cellInfoGroups, hiddenCellInfoGroupValues, prefilteredCellIdSet, selectedCellInfoGroupName, visibleSelectedComparisonDimensions])
}

export const useCellData = (
  ...params: Parameters<typeof useCellDataLogic>
): ReturnType<typeof useCellDataLogic> => {
  return useCellDataLogic(...params)
}

export default useCellData
