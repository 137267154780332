import { PlotDatum } from 'components/cell-visualizations/Compare/CompareGroups'
import ContinuousComparisonPlot from 'components/cell-visualizations/Compare/ContinuousComparisonPlot'
import { usePlotData } from 'components/cell-visualizations/plot/usePlotData'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import { useMemo } from 'react'
import { DifferentialFeaturesComparison, PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { convertMorphotypeIdsToCellIds } from './morphotypeListUtils'
import { getComparisonNameForOneSelection, getFeatureName } from './resultItemUtils'

type DistributionComparisonPlotProps = {
  comparison: DifferentialFeaturesComparison
  feature: keyof CellInfo
  width?: number // defaults to 500
  height?: number // defaults to 400
  showTitle?: boolean
}

/**
 * @internal - only exposed for unit testing
 * This function generates Plotly.js formatted plot data from a differential features comparison.
 *
 * @param comparison - The differential features comparison object.
 * @param feature - The name of the feature for to generate a comparison plot
 * @param cellInfo - Unfiltered cell information array to lookup feature values
 * @param morphotypes - Morphotype data to look up
 * @returns An array of plot data items (category, value pairs)
 */
export function getPlotDataForComparison(
  comparison: DifferentialFeaturesComparison,
  feature: keyof CellInfo,
  cellInfo: CellInfo[],
  morphotypes: PinnedMorphotype[]
): PlotDatum<number>[] {
  const result: PlotDatum<number>[] = []

  function addCellIds(morphotypeIds: number[], otherMorphotypeIds: number[]) {
    // Make sure we're calling this with the same inputs as in `CreateComparisonForm.tsx`
    // So that we get the same sets of cells
    const cellIdSet = new Set(
      convertMorphotypeIdsToCellIds(
        morphotypeIds,
        otherMorphotypeIds,
        cellInfo,
        morphotypes
      )
    )
    const name = getComparisonNameForOneSelection(morphotypeIds, morphotypes)

    cellInfo.forEach((cell) => {
      const cellId = cell.CELL_ID
      if (cellId && cellIdSet.has(cellId)) {
        const value = cell[feature]
        if (value !== undefined) {
          result.push({
            category: name,
            value: parseFloat(value),
          })
        }
      }
    })
  }

  addCellIds(comparison.morphotypeIds1, comparison.morphotypeIds2)
  addCellIds(comparison.morphotypeIds2, comparison.morphotypeIds1)

  return result
}

const DistributionComparisonPlot = ({
  comparison,
  feature,
  width = 500,
  height = 400,
  showTitle = true,
}: DistributionComparisonPlotProps): JSX.Element => {
  const { cellInfo } = usePlotData()
  const { getMergedPinnedMorphotypes } = useCellVisualizationsSlice()
  const morphotypes = getMergedPinnedMorphotypes()
  const divergenceScore = comparison.topFeatures?.find((f) => f.feature === feature)?.score
  const title = showTitle
    ? `${getFeatureName(feature)}<br>Divergence: ${divergenceScore?.toFixed(2)}`
    : undefined

  const plotData = useMemo(
    () => getPlotDataForComparison(comparison, feature, cellInfo, morphotypes),
    [comparison, feature, cellInfo, morphotypes]
  )

  return (
    <ContinuousComparisonPlot
      key={`distribution-comparison-${feature}`}
      data={plotData}
      title={title}
      titleFontSize={14}
      width={width}
      height={height}
    />
  )
}

export default DistributionComparisonPlot
