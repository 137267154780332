import useFlagCondition from 'components/shared/useFlagCondition'
import useFlags from 'components/shared/useFlags'
import { ColorBar } from 'plotly.js'
import { useMemo } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CellPlotData } from 'redux/slices/types'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { coalesce } from '../utils'
import getMarkerSize from './getMarkerSize'
import { BasePlotParams, Range } from './types'
import { calculateRangeForContinuousField } from './utils'



/**
 * Returns a function that will give you the start of a plot based on global hook values
 */
export const useBaseCellPlotData = (): {
  getBaseCellPlotData: (params: BasePlotParams) => CellPlotData
} => {
  const { isPreFilter } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const demoEnabled = useFlagCondition('demoEnabled')
  const showPreFilter = isPreFilter && preFilterEnabled && !demoEnabled

  const { cellVisualizationsDensityColorScale: densityColorScale } = useFlags()
  const densityReverseScale = useFlagCondition('cellVisualizationsReverseDensityColorScale')

  const { height: panelHeight } = usePanelHeight()
  const {
    cellVisualizations: { selectedCellInfoGroupName, cellInfoGroups, plotDisplayType, cellsData },
  } = useCellVisualizationsSlice()
  const markerSize = useMemo(() => getMarkerSize(cellsData?.length || 1), [cellsData?.length])

  const isDensity = plotDisplayType === 'density'
  const { isContinuous } = cellInfoGroups[selectedCellInfoGroupName] ?? {}

  // Memoize the return, so that components that depend on this don't re-render if the function isn't changing
  return useMemo(() => {
      /**
       * Gets the base Plotly plot data for a list of cells
       *
       * @param x Array of x coordinates to use.  These may be translated for multi-plot view from the original UMAP coordinates
       * @param y Array of y coordinates to use.  These may be translated for multi-plot view from the original UMAP coordinates
       * @param originalX Array of original X coordinates from the UMAP
       * @param originalY Array of original Y coordinates from the UMAP
       * @param name Name of the plot (e.g. Leiden cluster, category)
       * @param cellId Array of unique Cell Image ID values
       * @param color Color to use
       */

      const getBaseCellPlotData = ({
        x,
        y,
        originalX,
        originalY,
        name,
        cellId,
        color,
      }: BasePlotParams) => {
        const reversescale = coalesce(
          [isDensity, densityReverseScale]
        ) as boolean
        const colorscale = coalesce(
          [isDensity, densityColorScale]
        ) as string

        let colorbar =
          isDensity && !showPreFilter
            ? ({
                xref: 'paper',
                x: -0.05,
                lenmode: 'pixels',
                len: panelHeight - 100,
                ticks: 'outside',
              } as Partial<ColorBar>)
            : undefined

        let range: Range | undefined
        let margin = 0.0
        let customColorscale
        if (isContinuous && cellsData) {
          // Extract and filter values
          const allValues = cellsData
            .map((cell) => cell[selectedCellInfoGroupName as keyof typeof cell])
            .filter((value) => value !== undefined) as (string | number)[]

          // Calculate the range using outliers
          range = calculateRangeForContinuousField(allValues)

          // Calculate positions based on data range
          const fraction = 0.02
          margin = range ? (range.max - range.min) / (1 / fraction - 2) : 0

          customColorscale = [
            // Light blue for low outliers
            // 0.00 - 0.05 is outliers
            [0.00, '#ADD8E6'],
            [fraction * 0.9999, '#ADD8E6'],
            // YlOrRd for normal values
            [fraction, '#FFEDA0'],
            [0.50, '#FEB24C'],
            [1.00 - fraction, '#BD0026'],
            // Dark blue for high outliers
            // 0.95 - 1.00 is outliers
            [1.00 - fraction * 0.9999, '#000080'],
            [1.00, '#000080'],
          ]

          colorbar = range ? ({
            ticks: 'outside',
            tick0: range.min,
            dtick: (range.max - range.min) / 5,
          } as Partial<ColorBar>)
            : colorbar
        }

        return {
          type: 'scattergl',
          mode: 'markers',
          hoverinfo: 'none',
          opacity: 1,
          // @ts-ignore: This field is supported but not in the type definition for Plotly
          // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-unselected
          // By default, we fade out unselected points to indicate selection.  This makes it harder to make the next selection.
          // Instead, fade out the selected points slightly and make them bigger.
          unselected: {
            marker: {
              opacity: 1,
            },
          },
          // @ts-ignore: This field is supported but not in the type definition for Plotly
          // See docs here: https://plotly.com/javascript/reference/scatter/#scatter-selected
          selected: {
            marker: {
              size: markerSize * 1.7,
              opacity: 0.75,
            },
          },
          x: [x],
          y: [y],
          originalX: [originalX],
          originalY: [originalY],
          plotDatum: [{ x, y, cellId }],
          cellIds: [cellId],
          name,
          marker: {
            size: markerSize,
            color: [color],
            colorscale: isContinuous ? customColorscale : colorscale,
            showscale: false,
            reversescale,
            colorbar,
            ...(isContinuous && range ? {
              cmin: range.min - margin,
              cmax: range.max + margin,
            } : {})
          },
        } as CellPlotData
      }
      return { getBaseCellPlotData }
  }, [cellsData, densityColorScale, densityReverseScale, isContinuous, isDensity, markerSize, panelHeight, selectedCellInfoGroupName, showPreFilter])
}

export default useBaseCellPlotData
