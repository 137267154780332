import { CellInfo } from 'components/cell-visualizations/tsv/types'
import {
  getHiddenCellInfoGroupValues,
  getOriginalPlotSelectionState,
} from 'components/cell-visualizations/utils'
import useFlagCondition from 'components/shared/useFlagCondition'
import _ from 'lodash'
import { useMemo } from 'react'
import { getNewUniqueName, getRandomId } from 'utils/helpers'
import { cellVisualizationsSlice } from '../CellVisualizationsSlice'
import {
  CellInfoDimension,
  CellInfoGroup,
  CellInfoGroupNone,
  CellInfoGroups,
  PaginationParams,
  PinnedMorphotype,
  PlotSelectionState,
} from '../types'
import useEventsManager from './useEventsManager'
import usePlotlySlice from './usePlotlySlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
  const demoEnabled = useFlagCondition('demoEnabled')
  const enabledDensityPlot = useFlagCondition('enableDensityPlot')

  const rawSliceMethodsAndData = useSliceWrapper('cellVisualizations', cellVisualizationsSlice)
  const { resetRange } = usePlotlySlice()

  const sliceMethodsAndData = {
    ...rawSliceMethodsAndData,
    cellVisualizations: {
      ...rawSliceMethodsAndData.cellVisualizations,
      plotDisplayType: enabledDensityPlot
        ? rawSliceMethodsAndData.cellVisualizations.plotDisplayType
        : 'scatter',
    },
  } as typeof rawSliceMethodsAndData

  const {
    setShowMorphotypes,
    setPinnedCells,
    setPinnedGroupValue,
    setCellInfoGroups,
    setStore,
    cellVisualizations: {
      fileName,
      pinnedCells,
      cellsData,
      cellInfoGroups,
      selectedCellInfoGroupName,
      selectedComparisonDimensions,
      mergedPinnedCells,
      morphotypesToRemove,
    },
  } = sliceMethodsAndData

  const eventsManager = useEventsManager()

  const prefilteredCellIdSet = useMemo(
    () =>
      new Set<string>(
        morphotypesToRemove?.flatMap((m) => m.cells?.points?.flatMap((p) => `${p.cellId}`) ?? [])
      ),
    [morphotypesToRemove]
  )

  const byVisiblePinnedCells = useMemo(
    () =>
      <T extends Pick<PinnedMorphotype, 'cells'>>(pc: T) =>
        ({
          ...pc,
          cells: {
            ...pc.cells,
            points: pc.cells.points?.filter(({ cellId }) =>
              cellId ? !prefilteredCellIdSet.has(cellId) : true
            ),
          },
        } as T),
    [prefilteredCellIdSet]
  )

  const visiblePinnedCells = useMemo(
    () => pinnedCells?.map(byVisiblePinnedCells),
    [byVisiblePinnedCells, pinnedCells]
  )

  const visibleMergedPinnedCells = useMemo(
    () => mergedPinnedCells?.map(byVisiblePinnedCells),
    [byVisiblePinnedCells, mergedPinnedCells]
  )

  // need to use this ternary because it's undefined in tests for some reason
  const selectedCellInfoGroup = cellInfoGroups
    ? cellInfoGroups[selectedCellInfoGroupName ?? ''] ?? CellInfoGroupNone
    : CellInfoGroupNone

  const { hiddenCellInfoGroupValues, hiddenValuesExist } = useMemo(() => {
    const vals = getHiddenCellInfoGroupValues(cellInfoGroups)
    return { hiddenCellInfoGroupValues: vals, hiddenValuesExist: !!_.keys(vals).length }
  }, [cellInfoGroups])

  const visibleSelectedComparisonDimensions = useMemo(() => {
    const visible: CellInfoDimension[] | undefined = selectedComparisonDimensions?.flatMap(
      (scd) => ({
        ...scd,
        values: scd.values.filter((v) =>
          hiddenCellInfoGroupValues[scd.field]
            ? !hiddenCellInfoGroupValues[scd.field]?.includes(v)
            : true
        ),
      })
    )
    return visible
  }, [hiddenCellInfoGroupValues, selectedComparisonDimensions])

  const cellIdMap = useMemo(() => {
    const nextCellIdMap: Record<string, CellInfo> = {}
    cellsData?.forEach((cell) => {
      nextCellIdMap[cell.CELL_ID] = cell
    })
    return nextCellIdMap
  }, [cellsData])

  const helperMethods = useMemo(() => {
    return {
      clearCellData: () => {
        resetRange()
        setStore({})
      },
      setPointsAsSelected: (currentSelection?: PlotSelectionState) => {
        if (currentSelection) {
          /*
                  there's a bug?/feature? in plotly where if the layout.shapes
                  and/or layout.annotations are updated then the onSelected gets
                  triggered with e.points and e.selections as empty arrays
                  */
          const { points, selections, lassoPoints, range } = currentSelection
          if (points && points.length > 0 && selections && selections.length > 0) {
            // don't include points that have been filtered

            setShowMorphotypes(true)
            if (lassoPoints) {
              eventsManager.sendLassoEvent(fileName as string, points.length)
            } else if (range) {
              eventsManager.sendRectangleEvent(fileName as string, points.length)
            }

            const currentSelectionState = {
              ...currentSelection,
              // @TODO Figure out why we need to make a copy of the points.  It doesn't work otherwise?
              points: points?.map((point) => ({
                x: point.x,
                y: point.y,
                originalX: point.originalX,
                originalY: point.originalY,
                cellId: point.cellId,
              })),
              selections: selections?.map((selection) => ({
                type: selection.type,
                x0: selection.x0,
                y0: selection.y0,
                x1: selection.x1,
                y1: selection.y1,
                path: selection.path,
              })),
            } as PlotSelectionState

            if (!demoEnabled) {
              const newPinnedCells = [
                // Place the new selection first, so that the user is more likely to see it
                // @TODO Scroll to the new selection in the future
                {
                  // default is 1000 random ids, which should be enough...
                  id: getRandomId({ exclude: pinnedCells?.map((x) => x.id) }),
                  name: getNewUniqueName(pinnedCells),
                  cells: getOriginalPlotSelectionState(currentSelection),
                  transposedCells: currentSelectionState,
                  active: true,
                  // pagination: statePagination,
                  comparisonDimensionsSnapshot: selectedComparisonDimensions,
                  cellInfoGroupsSnapshot: hiddenValuesExist
                    ? _.keys(cellInfoGroups).reduce((acc, k) => {
                        const key = k as keyof typeof cellInfoGroups
                        if (hiddenCellInfoGroupValues[key]) {
                          return { ...acc, [key]: cellInfoGroups[key] }
                        }
                        return acc
                      }, {} as CellInfoGroups)
                    : undefined,
                } as PinnedMorphotype,
                ...(pinnedCells ?? []),
              ]
              setPinnedCells({ pinnedCells: newPinnedCells, eventsManager })
            }
          }
        }
      },
      getCellInfoByCellId: (cellId: string): CellInfo => {
        return cellIdMap[cellId] ?? {}
      },

      setCellInfoGroupData: (key: keyof CellInfoGroups, data: CellInfoGroup['data']) => {
        setCellInfoGroups({ ...cellInfoGroups, [key]: { ...cellInfoGroups[key], data } })
      },

      setSelectedCellInfoGroupData: (data: CellInfoGroup['data']) => {
        setCellInfoGroups({
          ...cellInfoGroups,
          [selectedCellInfoGroupName]: { ...cellInfoGroups[selectedCellInfoGroupName], data },
        })
      },
      getMergedPinnedMorphotypes: () =>
        pinnedCells?.reduce((acc, pinnedMorphotype) => {
          const existingPinnedMorphotypeIndex = acc.findIndex((x) => x.id === pinnedMorphotype.id)
          if (existingPinnedMorphotypeIndex > -1) {
            return acc.map((accPinnedMorphotype, i) => {
              if (i === existingPinnedMorphotypeIndex) {
                const points = mergedPinnedCells?.find((x) => x.id === accPinnedMorphotype.id)?.cells
                  .points
                return {
                  ...accPinnedMorphotype,
                  cells: {
                    ...accPinnedMorphotype.cells,
                    points,
                  },
                }
              }
              return accPinnedMorphotype
            })
          }
          return [...acc, pinnedMorphotype]
        }, [] as PinnedMorphotype[]) ?? [],
      setPinnedGroupHighlighted: ({
        pinnedGroupId,
        isHighlighted = true,
      }: {
        pinnedGroupId: number
        isHighlighted?: boolean
      }) => {
        setPinnedGroupValue({
          pinnedGroupId,
          key: 'isHighlighted',
          value: isHighlighted,
        })
      },
      setPinnedGroupPagination: ({
        pinnedGroupId,
        pagination,
      }: {
        pinnedGroupId: number
        pagination: PaginationParams
      }) => {
        setPinnedGroupValue({
          pinnedGroupId,
          key: 'pagination',
          value: pagination,
        })
      },

      updatePinnedName: ({ pinnedGroupId, name }: { pinnedGroupId: number; name: string }) =>
        setPinnedGroupValue({ pinnedGroupId, key: 'name', value: name }),
      setPinnedGroupActive: ({
        pinnedGroupId,
        active,
      }: {
        pinnedGroupId: number
        active?: boolean
      }) =>
        setPinnedGroupValue({
          pinnedGroupId,
          key: 'active',
          value: Boolean(active),
        }),
    }
  }, [cellIdMap, cellInfoGroups, demoEnabled, eventsManager, fileName, hiddenCellInfoGroupValues, hiddenValuesExist, mergedPinnedCells, pinnedCells, resetRange, selectedCellInfoGroupName, selectedComparisonDimensions, setCellInfoGroups, setPinnedCells, setPinnedGroupValue, setShowMorphotypes, setStore])

  return {
    ...sliceMethodsAndData,
    ...helperMethods,
    selectedCellInfoGroup,
    hiddenCellInfoGroupValues,
    visibleSelectedComparisonDimensions,
    visiblePinnedCells,
    visibleMergedPinnedCells,
    prefilteredCellIdSet,
  } as const
}

export const useCellVisualizationsSlice = (): ReturnType<typeof useGetMethodsAndData> =>
  useGetMethodsAndData()

export type UseCellVisualizationsSlice = ReturnType<typeof useCellVisualizationsSlice>
