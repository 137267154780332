import UploadIcon from '@mui/icons-material/Upload'
import { DeepcellPrimaryButton } from 'components/shared'
import { ChangeEvent, useRef } from 'react'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import useSessionApi from '../useSessionApi'

const validExtensions = ['tsv', 'zip']
const requiredColumns = ['UMAP_0', 'UMAP_1']

export const UploadCellDataTsvButton = (): JSX.Element => {
  const { showError } = useNotificationSlice()
  const eventsManager = useEventsManager()

  const { uploadNewBlobToCloud } = useSessionApi()

  const validateFile = async (file: File): Promise<boolean> => {
    // Read the file content as text
    const content = await file.text()

    // Split the content into rows, removing any empty lines
    const rows = content.split('\n').filter(Boolean)

    // Extract column names from the first row (header)
    const columns = rows[0].split('\t')

    // Step 1: Check for missing mandatory columns in the header
    const missingColumns = requiredColumns.filter((col) => !columns.includes(col))
    if (missingColumns.length > 0) {
      // Show an error if mandatory columns are missing in the header
      showError(`The uploaded file is missing the mandatory columns: ${missingColumns.join(', ')}`)
      return false
    }

    // Step 2: Check for missing values in mandatory columns for each row
    const missingColumnsNames: Set<string> = new Set() // To track missing column values
    const columnIndices = requiredColumns.map((col) => columns.indexOf(col)) // Indices of required columns in the header

    // Iterate over each data row (starting from the second row)
    for (let i = 1; i < rows.length; i += 1) {
      const row = rows[i].split('\t') // Split the row into individual column values

      // Check each required column for missing values
      columnIndices.forEach((index, idx) => {
        // Check only for empty/whitespace values
        if (!row[index] || row[index].trim() === '') {
          missingColumnsNames.add(requiredColumns[idx])
        }
      })
    }

    // Step 3: If there are missing values in any required column, show an error
    if (missingColumnsNames.size > 0) {
      const missingColumnsArray = Array.from(missingColumnsNames) // Convert the Set to an array
      const message =
        missingColumnsArray.length === 1
          ? `Values missing for ${missingColumnsArray[0]} for some rows.` // Singular message for one column
          : `Values missing for ${missingColumnsArray.join(' and ')} for some rows.` // Plural message for multiple columns

      // Show the error message with the missing columns
      showError(message)
      return false
    }

    // If all validations pass, return true
    return true
  }

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]

    const extension = file?.name?.split('.').slice(-1)[0]

    if (validExtensions.includes(extension)) {
      const isValid = await validateFile(file)
      if (isValid) {
        uploadNewBlobToCloud({ dataFromBlob: file, fileName: file.name })
      }
    } else {
      eventsManager.sendDataUploadEvent(file, false)
      showError(
        `You've uploaded a .${extension} file, but the supported extensions are .tsv and .zip`
      )
    }
  }

  const uploadInputRef = useRef<HTMLInputElement>(null)

  return (
    <DeepcellPrimaryButton
      sx={{ width: 230 }}
      startIcon={<UploadIcon />}
      contained
      onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
    >
      Upload Cell Data TSV
      <input ref={uploadInputRef} hidden type="file" onChange={onUpload} />
    </DeepcellPrimaryButton>
  )
}

export default UploadCellDataTsvButton
