import { useMemo } from 'react'
import { plotlySlice } from '../PlotlySlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
  const methodsAndData = useSliceWrapper('plotly', plotlySlice)
  const { setRange } = methodsAndData

  // useMemo is used here to ensure that the helper methods are stable and do not trigger unnecessary re-renders
  const helperMethods = useMemo(() => {
    /**
     * SHOULD set the range to be the extrema of the points of the plot. But who knows what plotly is doing under the hood...
     */
    return {
      resetRange: () => {
        setRange({ x1: undefined, x2: undefined, y1: undefined, y2: undefined })
      }
    }
  }, [setRange])

  return { ...methodsAndData, ...helperMethods }
}

export const usePlotlySlice = (): ReturnType<typeof useGetMethodsAndData> => useGetMethodsAndData()

export default usePlotlySlice
