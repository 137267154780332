import { Buffer } from 'buffer'
import * as ENVIRONMENTS from 'constants/environments'
import { Cell, CellId, Run as RunProto } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import axios, { AxiosRequestConfig, AxiosResponse, CancelToken, InternalAxiosRequestConfig, Method, ResponseType } from 'axios'
import { ENABLE_MOCK_API, ENABLE_STAGING_API_FOR_LOCAL_DEV } from 'config'
import qs from 'qs'
import { QueryFunctionContext } from 'react-query'
import { CellVisualizationsState, TopFeature } from 'redux/slices'
import { DEV_BASE_URL } from '../config/dev'
import { getBatchURLAPIRoute, getCellImagesAPIRoute } from './demoEnvironmentUtils'
import plotlyImageToDataURL from './plotly-image-to-dataURL'

// @TODO: Figure out why this ↓↓ is needed. Maybe something with the React update? Chrome? react-scripts v5?
// @ts-ignore Overwrite the Buffer with the imported one
window.Buffer = Buffer

export const PROD_BASE_URL = 'https://api-dot-dc-classifier.appspot.com'

const initApiUrl = () => {
  if (process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT) {
    if (ENABLE_STAGING_API_FOR_LOCAL_DEV) {
      // Use this one if you'd like to use the Staging API server for local development
      return 'https://api-staging-dot-dc-classifier.appspot.com'
    }
    // By default, use the local API
    return 'http://localhost:8080'
  }
  if (process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING) {
    return 'https://api-staging-dot-dc-classifier.appspot.com'
  }
  if (process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO) {
    return 'https://api-dot-dc-classifier.appspot.com'
  }
  // prod or unit testing (uses mocked prod URLs via mswjs.io)
  if (
    process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION ||
    process.env.REACT_APP_ENV === undefined
  ) {
    return PROD_BASE_URL
  }
  return DEV_BASE_URL
}

// Initialize the API url
export const API_URL = initApiUrl()

export interface RunProtoQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  run_ids?: string[]
  order_by?: string
  limit?: number
  offset?: number
  include_count?: boolean
}

export interface CellsQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  number?: number
  time?: number
  predicted_classes?: number[]
  predicted_probability_greater_than?: number
  morphology_classifiers?: string[]
  morphology_classifiers_probability_greater_than?: number
  before?: number
  after?: number
  run_ids?: string[]
  order_by?: string
  limit: number
  offset?: number
  include_estimated_count?: boolean
  is_bead?: boolean
  is_channel_bar?: boolean
  is_cut_cell?: boolean
}

export interface RunIdParams {
  run_ids: string[]
}

export interface CellsMetricsQueryParams {
  run_ids?: string
  include_estimated_count?: boolean
  quadrant: number
  include_image_urls?: boolean
}

export interface CellListCellsQueryParams {
  cell_list_id?: number
  limit?: number
  offset?: number
  include_image_urls?: boolean
}

export interface CellImageFilters {
  centerCropSize?: number
  unsharpAmount?: number
  unsharpRadius?: number
}

export interface GetCellImageParams {
  number?: number
  time?: number
  instrumentId?: number
  frame: number
  width?: number
  filters?: CellImageFilters
}

export interface CellIdParam {
  number?: number
  time?: number
  instrumentId?: number
}

export interface SamplesQueryParams {
  sample_id?: string
  mixed_sample_id?: string
  sample_type?: number
  limit: number
}

export const DefaultCellsQueryParams: CellsQueryParams = {
  limit: 500,
}

export const DefaultRunsQueryParams: RunProtoQueryParams = {
  limit: 100000,
  include_count: true,
}

export interface FindCellsResponse {
  cells: CellResponse[]
  count?: number
  countIsEstimate?: boolean
}

export interface FindMorphologyTypesResponse {
  morphoTypes: string[]
}

export interface CellResponse {
  cell: Cell
  imageUrls: string[]
}

export interface GetCellListCellsResponse {
  cells: CellResponse[]
}

export interface FindRunsResponse {
  runs: RunProto[]
  count?: number
}

interface GetCellImageUrlsResponse {
  image_urls: string[]
}

/** Run JSON response format */
export interface GetRunsParams {
  q?: string
  limit?: number
  offset?: number
  run_ids?: string[]
  project_code?: string
  user_email?: string
  start_time_max?: string // ISO8601 Date Time
  start_time_min?: string // ISO8601 Date Time
  stopped?: boolean
  stop_reason?: string
  sample_types?: string[]
  order_by?: string
}

export interface Run {
  camera_model?: string
  camera_serial?: string
  camera_settings?: Record<string, unknown>
  cell_counts?: CellCount[]
  cell_detect_window?: string
  cell_image_size?: string
  chip_barcode?: string
  classification_counts?: Record<string, unknown>
  counters?: Record<string, unknown>
  description?: string
  experiment_code?: string
  stop_elapsed_time?: number
  stop_imaged_count?: number
  stop_sorted_count?: number
  exposure_time?: number
  frame_rate?: number
  id: number
  image_size?: string
  inference_type?: string
  instrument_name?: string
  instrument_serial_number?: string
  last_delay_time?: number
  metrics?: Record<string, unknown>
  model?: string
  monitoring_info?: Record<string, unknown>
  organization_id: string
  periodic_flush?: Record<string, unknown>
  positive_rate?: number
  project_code?: string
  random_percent?: number
  run_id: string
  run_notes?: string
  run_type?: string
  sample_id?: string
  sample_type?: string
  sample_kind?: string
  start_time: string // ISO8601 Date Time
  status?: string
  stop_on_errors?: boolean
  stop_time?: string // ISO8601 Date Time
  stopped?: boolean
  stop_reason?: string
  stopped_by_metric?: string
  target_cell_classes?: number[]
  total_cell_count: number
  total_sorted_count?: number
  update_time?: number
  user_email: string
  valve_pattern?: Record<string, unknown>
  versions?: Versions
  well_counts?: WellCount[]
  well_metrics?: WellMetric[]
  well_sorting_configurations?: WellConfiguration[]
  run_quality_score?: number
  run_quality_approved?: boolean
  run_quality_approved_email?: string
  run_quality_score_email?: string
  run_quality_metrics: RunQualityMetrics
}

export interface RunQualityMetrics {
  1: RunQualityMetric
  2: RunQualityMetric
  3: RunQualityMetric
  4: RunQualityMetric
}

export interface RunQualityMetric {
  total_image_count: number
  cutoff_image: number
  contamination_covering_image: number
  chip_blemish_covering_image: number
  blebbing_image: number
  cell_debris: number
}
export interface Versions {
  libdc?: string
  dsp_fw?: string
  dsp_hw?: string
  camera_fw?: string
  classifier?: string
  controller?: string
  morphometrics?: string
  cell_image_library?: string
  bead_focus_model_family?: string
  sorting_fpga?: string
}

export interface WellConfiguration {
  well: number
  cell_types: string[]
  threshold: number
  stop_count?: number
  delay_time_delta?: number
}

export interface WellCount {
  well: number
  count: number
}

export interface CellCount {
  cell_type: string
  count: number
}

export interface WellMetric {
  well: number
  true_positive_rate: number
  purity: number
}

export interface RunsVisSessionResponse {
  run_id: string
  sample_id: string
  sample_type: string
}

export type GetRunsResult = Run[]
export type GetWellConfigurationResult = WellConfiguration[]
export type GetWellCountResult = WellCount[]
export type GetWellMetricResult = WellMetric[]

export class ClientError extends Error {
  data: unknown

  constructor(message: string, data: unknown) {
    super(message)
    this.data = data
  }
}

let bearerToken = ''
export function updateBearerToken(token: string): void {
  bearerToken = token
}


axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Ensure headers exist and use the `set` method to add the Authorization header
    config.headers?.set('Authorization', `Bearer ${bearerToken}`);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export class ProcessingIncomplete extends Error { }

export class NoContentReturned extends Error { }

export class ConflictError extends Error { }

const sendApiRequest = async <ApiResponseType>(
  path: string,
  {
    method = 'GET',
    params = {},
    data = {},
    responseType = 'json',
    cancelToken,
  }: {
    method?: Method
    params?: unknown
    data?: unknown
    responseType?: ResponseType
    cancelToken?: CancelToken
  }
): Promise<AxiosResponse<ApiResponseType>> => {
  const options: AxiosRequestConfig = {
    method,
    // Change domain to api-[your username]-dot-dc-classifier.appspot.com to run against your development API.
    url: `${API_URL}/${path}`,
    validateStatus(status) {
      return status >= 200 && status <= 500
    },
    params,
    data,
    responseType,
    paramsSerializer(requestParams) {
      return qs.stringify(requestParams, { arrayFormat: 'comma' })
    },
    cancelToken,
  }
  const res = await axios(options)

  if (res.status === 202) {
    // Handle 202 response specifically
    return res;
  }

  // Pending requests might need to be handled better
  if (res.status !== 200 && res.status !== 201) {
    console.error('API request failed.', res)

    if (res.status === 204) {
      throw new NoContentReturned()
    }

    if (res.status === 500) {
      const message =
        res.data && res.data.error ? res.data.error : 'The server may be down.  Ask engineers.'
      throw Error(message)
    }

    if (res.status === 409) {
      throw new ConflictError()
    }

    if (res.status >= 400 && res.status < 500) {
      if (res.data.error) {
        console.error('data=', res.data)
        throw new ClientError(res.data.error, res.data)
      }
    }
    throw Error('Unknown error.  Ask engineers')
  }

  return res
}

interface ApiCell {
  proto: string
  image_urls: string[]
}

function decodeCells(cells: ApiCell[]) {
  return cells.map((x: ApiCell) => {
    const base64str = x.proto
    const buf = Buffer.from(base64str, 'base64')

    // Convert Buffer to Uint8Array
    const uint8Array = new Uint8Array(buf);

    return {
      cell: Cell.deserializeBinary(uint8Array),
      imageUrls: x.image_urls,
    }
  })
}


async function getCellsHelper(params: CellsQueryParams): Promise<FindCellsResponse> {
  interface CellApiResponse {
    cells: ApiCell[]
    count?: number
    count_is_estimate?: boolean
  }
  // Enabling QC filters by default
  const enabledQCFilters: CellsQueryParams = {
    ...params,
    is_bead: false,
    is_cut_cell: false,
    is_channel_bar: false,
  }

  const res = await sendApiRequest<CellApiResponse>('v1/cells/cells', { params: enabledQCFilters })

  if (res.data.cells.length > params.limit) {
    throw Error('Got more cells than expected')
  }

  const result: FindCellsResponse = {
    cells: decodeCells(res.data.cells),
  }
  if (res.data.count !== undefined) {
    result.count = res.data.count
    result.countIsEstimate = res.data.count_is_estimate
  }

  return result
}

async function getMorphoTypesHelper(params: RunIdParams): Promise<FindMorphologyTypesResponse> {
  const enabledParams: RunIdParams = { ...params }
  const res = await sendApiRequest<string[]>('v1/runs/morphology-classifiers', {
    params: enabledParams,
  })
  const result: FindMorphologyTypesResponse = {
    morphoTypes: res.data,
  }
  return result
}

async function getCellsMetricsHelper(params: CellsMetricsQueryParams): Promise<FindCellsResponse> {
  interface CellApiResponse {
    cells: ApiCell[]
    count?: number
    count_is_estimate?: boolean
  }
  const res = await sendApiRequest<CellApiResponse>('v1/cells/quality_review', { params })

  const result: FindCellsResponse = {
    cells: decodeCells(res.data.cells),
  }
  if (res.data.count !== undefined) {
    result.count = res.data.count
    result.countIsEstimate = res.data.count_is_estimate
  }

  return result
}

export async function getCells(
  context: QueryFunctionContext<[string, CellsQueryParams]>
): Promise<FindCellsResponse> {
  const params = context.queryKey[1]
  return getCellsHelper(params)
}

export async function getMorphologyClassifiers(
  context: QueryFunctionContext<[string, RunIdParams]>
): Promise<FindMorphologyTypesResponse> {
  const params = context.queryKey[1]
  return getMorphoTypesHelper(params)
}

export async function getMetricsCells(
  context: QueryFunctionContext<[string, CellsMetricsQueryParams]>
): Promise<FindCellsResponse> {
  const params = context.queryKey[1]
  return getCellsMetricsHelper(params)
}

export async function getCellListCells(
  context: QueryFunctionContext<[string, CellListCellsQueryParams]>
): Promise<GetCellListCellsResponse> {
  const params = context.queryKey[1]
  interface CellApiResponse {
    cells: ApiCell[]
  }
  const res = await sendApiRequest<CellApiResponse>(`v1/cells/lists/${params.cell_list_id}/cells`, {
    params,
  })

  return {
    cells: decodeCells(res.data.cells),
  }
}

async function getCellImageHelper(params: GetCellImageParams): Promise<string> {
  if (params.number === null || params.number === undefined) return ''

  const cellImagesAPIRoute = getCellImagesAPIRoute(process.env.REACT_APP_ENV)
  const res = await sendApiRequest<Blob>(cellImagesAPIRoute, {
    method: 'GET',
    params: {
      number: params.number,
      time: params.time,
      instrument_id: params.instrumentId,
      frame: params.frame,
      width: params.width,
      center_crop_size: params.filters?.centerCropSize,
      unsharp_amount: params.filters?.unsharpAmount,
      unsharp_radius: params.filters?.unsharpRadius,
    },
    responseType: 'blob',
  })

  return new Promise((resolve) => {
    if (res.data) {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(res.data)
    }
  })
}

export async function getCellImage(
  context: QueryFunctionContext<[string, GetCellImageParams]>
): Promise<string> {
  const params = context.queryKey[1]
  return getCellImageHelper(params)
}

export interface CellImageId {
  cellId: CellId,
  frame: number | null
}

interface FlatCellImageId {
  time: number
  number: number
  instrument_id: number
  frame: number
}

function cellImageIdToFlatObject(cellImageId: CellImageId): FlatCellImageId {
  // all the -1 stuff means it is still type safe but you probably won't be getting an image back
  // CellId seems to populate its values with 0 or -1 befre they are set
  return {
    time: cellImageId.cellId.getTime() ?? -1,
    number: cellImageId.cellId.getNumber() ?? -1,
    instrument_id: cellImageId.cellId.getInstrumentId() ?? -1,
    frame: cellImageId.frame ?? 0  // default to 0 if not specified, like everywhere else
  }
}

/** Fetches a batch of signed image URLs by CellId.  These URLs expire after 30 minutes */
export async function getCellImageURLs(
  context: QueryFunctionContext<[string, CellImageId[]]>
): Promise<string[]> {
  const cellImageIds = context.queryKey[1]

  const batchURLAPIRoute = getBatchURLAPIRoute(process.env.REACT_APP_ENV)
  const res = await sendApiRequest<GetCellImageUrlsResponse>(batchURLAPIRoute, {
    method: 'POST',
    data: {
      cell_ids: cellImageIds.map(cellImageIdToFlatObject)
    }
  })
  return res.data.image_urls
}

/** Fetches a batch of images by CellId and returns DataURLs. */
export async function getCellImageDataURLs(
  context: QueryFunctionContext<[string, CellImageId[]]>
): Promise<string[]> {
  const imageUrls = await getCellImageURLs(context)
  const dataURLs = await Promise.all(imageUrls.map((url) => plotlyImageToDataURL(url)))
  return dataURLs
}

export async function getRuns(
  context: QueryFunctionContext<[string, GetRunsParams]>
): Promise<GetRunsResult> {
  const params = context.queryKey[1]
  const res = await sendApiRequest<GetRunsResult>('v1/runs:search', {
    params,
  })
  return res.data
}

/**
 * Represents the body request to update a single run.
 * @see http://api-dot-dc-classifier.uc.r.appspot.com/api/docs/#/Runs/runs-annotate-v1
 */
export interface UpdateRunRequest {
  run_id: string
  project_code?: string
  experiment_code?: string
  run_notes?: string
  description?: string
  target_cell_classes?: number[]
}
/**
 * Call the cell data api to update the given run.
 * @see http://api-dot-dc-classifier.uc.r.appspot.com/api/docs/#/Runs/runs-annotate-v1
 */
export async function updateRun(data: UpdateRunRequest): Promise<Run> {
  const res = await sendApiRequest<Run>(`v1/runs/${data.run_id}:annotate`, {
    method: 'PATCH',
    data,
  })
  return res.data
}

export function convertErrorToString<TData, TError>(response: {
  data: TData
  error?: TError
}): { result: TData; error: string | undefined } {
  let stringError: string | undefined
  const { data, error } = response

  if (error) {
    console.error(error)
    if (error instanceof Error) {
      stringError = error.message
    } else if (typeof error === 'string') {
      stringError = error
    } else {
      stringError = 'Unknown error occurred.'
    }
  }

  return {
    result: data,
    error: stringError,
  }
}

export interface DeleteSessionParams {
  id: number | string
}

export async function deleteSession(params: DeleteSessionParams): Promise<AxiosResponse> {
  const { id } = params
  const res = await sendApiRequest(`v1/cell_visualizations/sessions/${id}`, {
    method: 'DELETE',
  })

  return res
}

export interface SessionPostData {
  author_email?: string
  /**
   * Only populated from a session that had its classifier trained
   */
  cell_group_classifier_model_id?: string
  /**
   * Contains an signed, expiring URL that points to the arrow file
   */
  data_url?: string
  /**
   * Only populated from a session that had its classifier trained
   */
  projection_model_id?: string
  session_id: string
  version_id: string
  version_config?: Partial<CellVisualizationsState>
  updated_at?:string
  runs?:RunsVisSessionResponse[]
  status?:string
}

export interface SessionPostResponse {
  data?: SessionPostData
  status?: string
}

export interface SessionSaveResponse {
  status?: string
  version_id?: string
}

export async function getArrowFile(data_url: string): Promise<Response> {
  // TODO: Figure out how to return arrow file from msw so that we can remove this 'if' statement
  if (ENABLE_MOCK_API && process.env.NODE_ENV === 'development') {
    const arr = await fetch('/24.arrow')
    return arr
  }
  const arr = await fetch(data_url)
  return arr
}

export async function getSession(
  sessionId: string | number,
  versionId?: string | number
): Promise<AxiosResponse<SessionPostResponse>> {

    // TODO: Address this ticket: https://deepcellbio.atlassian.net/browse/SWT-382
    // This should go away when we address SWT-382
    // Use useQuery instead and not implement our own custom logic

       const res = await sendApiRequest<SessionPostResponse>(
          `v1/cell_visualizations/sessions/${sessionId}${versionId ? `/versions/${versionId}` : ''}`,
          {
            method: 'GET',
          }
        )
        return res
     }

export type SaveSessionParams = {
  state: Partial<CellVisualizationsState>
  sessionId: number
  versionId: number
  isCopy?: boolean
  trainClassifier?: boolean
}
export interface SessionMetaData {
  author_email: string
  created_at: Date | null
  data_url: string
  deleted_at: Date | null
  is_latest: boolean
  session_id: number
  updated_at: Date
  version_config: CellVisualizationsState
  version_id: number
  status:string
  runs:RunsVisSessionResponse[]
}

export interface SessionMetaDataResponse {
  data: SessionMetaData[]
  status: string
}

type CVSKey = keyof CellVisualizationsState

export interface GetSessionsParams {
  version_config_fields?: CVSKey[]
  offset?: number
  limit?: number
  order_by?:string
  run_ids?: string;
  session_name?: string;
  author_email?: string;
  classifier_name?: string;
  sample_type?: string;
  sample_id?: string;
  keywords?: string[];

}

export async function getSessions(
  params?: GetSessionsParams
): Promise<AxiosResponse<SessionMetaDataResponse>> {
  const transformedParams = {
    ...params,
    version_config_fields: params?.version_config_fields?.toString(),
  }
  const res = await sendApiRequest<SessionMetaDataResponse>('v1/cell_visualizations/sessions', {
    method: 'GET',
    params: transformedParams,
  })

  return res
}

export async function saveSession(params: SaveSessionParams): Promise<SessionSaveResponse> {
  const { state, sessionId, versionId, isCopy, trainClassifier } = params

  const data = {
    parent_version: versionId,
    version_config: state,
    train_classifier: trainClassifier,
  }

  const baseUrl = `v1/cell_visualizations/sessions/${sessionId}`

  const isCopyRoute = isCopy ? `/versions/${versionId}:copy` : ''

  const url = `${baseUrl}${isCopyRoute}`

  const res = await sendApiRequest<SessionPostResponse>(url, {
    method: 'POST',
    data,
  })

  return res.data
}

export async function createSessionFromFile(
  dataToUpload: Blob,
  fileName: string
): Promise<AxiosResponse<SessionPostData>> {
  const data = new FormData()
  data.append('file', dataToUpload, fileName)
  const res = await sendApiRequest<SessionPostData>('v1/cell_visualizations/sessions', {
    method: 'POST',
    data,
  })
  return res
}

export interface VisualizationSessionConfig {
  runs: ({ run_id?: string } & Record<string, unknown>)[]
  max_cell_count: number
  custom_projection_model_name?: string
  morphometric_model: string
  dl_only_projection: boolean // Required field (no question mark)
  cancelToken?: CancelToken
  name: string
  sampling_strategy: SamplingStrategy
  leiden_resolution: number
  projection_params: {
    n_neighbors: number
    min_dist: number
    spread: number
  }
}

export async function createSessionFromRuns(
  data: VisualizationSessionConfig
): Promise<AxiosResponse<SessionPostData>> {
  const { cancelToken, ...payload } = data
  const res = await sendApiRequest<SessionPostData>('v1/cell_visualizations/sessions', {
    method: 'POST',
    data: payload,
    cancelToken
  })
  return res
}

export interface DemoTokenGetResponse {
  token?: string
}

export async function getDemoToken(): Promise<AxiosResponse<DemoTokenGetResponse>> {
  const res = await sendApiRequest<DemoTokenGetResponse>('v1/auth/demosite', {
    method: 'GET',
  })

  return res
}

export type FieldsAndTypes = {
  fields: {
    name: string // 'G2'
    type: string // 'float32'
  }[]
  format_version: '1.0.0'
}

export interface ModelResponse {
  id: {
    name: string // 'hfm_d6'
    version: number // 1
  }
  publisher_email: string // 'bryce@deepcellbio.com',
  outputs: {
    name: string // 'output'
    output_type: string // 'cell_group_classification'
    output_schema: {
      // fields_and_types comes in as a stringified JSON
      fields_and_types: string // '{"fields": [{"name": "G2", "type": "float32"}, {"name": "G1", "type": "float32"}, {"name": "FlowCyte_3", "type": "float32"}, {"name": "10 um", "type": "float32"}], "format_version": "1.0.0"}'
      transformation_function: string // 'Identity'
    }
  }[]
  inputs: string[] // 'projection'
  input_model: {
    name: string // hfm_d6_projection
    version: number // 1
  }
  gcs_model_zipped_path: string // "gs://dc-model-service/prod/hfm_d6_1.zip",
  global_model: boolean
}

export async function getModel(
  modelName: string,
  modelVersion: number
): Promise<AxiosResponse<ModelResponse>> {
  const res = await sendApiRequest<ModelResponse>(`v2/models/${modelName}/${modelVersion}`, {
    method: 'GET',
  })

  return res
}

export async function getCompressedModel(
  modelName: string,
  modelVersion: number
): Promise<AxiosResponse<{ url: string }>> {
  const res = await sendApiRequest<{ url: string }>(
    `v2/models/compressed/${modelName}/${modelVersion}`,
    {
      method: 'GET',
    }
  )

  return res
}

export interface DifferentialFeaturesTaskInput {
  sessionId: number
  versionId: number
  cellIdList1: string[]
  cellIdList2: string[]
}

export enum TaskStatus {
  PENDING = 'pending',
  READY = 'ready',
  FAILED = 'failed',
}

export enum SamplingStrategy {
  PROPORTIONAL = 'proportional',
  EQUAL_BY_RUN = 'equal_by_run',
  EQUAL_BY_SAMPLE = 'equal_by_sample',
}

export interface DifferentialFeaturesTaskResult {
  task_id: number
  status: TaskStatus
  result?: {
    top_features: TopFeature[]
  }
}

export async function startDifferentialFeaturesTask(
  context: QueryFunctionContext<[string, DifferentialFeaturesTaskInput]>
): Promise<AxiosResponse<DifferentialFeaturesTaskResult>> {
  const input = context.queryKey[1]
  const requestData = {
    cell_id_list_1: input.cellIdList1,
    cell_id_list_2: input.cellIdList2,
  }
  const res = await sendApiRequest<DifferentialFeaturesTaskResult>(
    `v1/cell_visualizations/sessions/${input.sessionId}/versions/${input.versionId}/tasks:differential_features`,
    {
      method: 'POST',
      data: requestData,
    }
  )
  return res
}

export interface DifferentialFeaturesGetTaskInput {
  sessionId: number
  versionId: number
  taskId: number
}

export async function getDifferentialFeaturesResult(
  context: QueryFunctionContext<[string, DifferentialFeaturesGetTaskInput]>
): Promise<AxiosResponse<DifferentialFeaturesTaskResult>> {
  const input = context.queryKey[1]
  const res = await sendApiRequest<DifferentialFeaturesTaskResult>(
    `v1/cell_visualizations/sessions/${input.sessionId}/versions/${input.versionId}/tasks/${input.taskId}`,
    {}
  )
  return res
}

export async function getEmbeddingsId(params: { run_ids: string[] }): Promise<AxiosResponse> {
  const res = await sendApiRequest('v1/runs/embeddings', {
    params,
  })
  return res
}

export async function getEmbeddingsURL(requestId:number):Promise<AxiosResponse>{
  const res = await sendApiRequest(`v1/runs/embeddings/${requestId}`,{})
  return res
}
