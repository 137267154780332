import useDeepcellError, { ReasonCode } from 'components/shared/useDeepcellError'
import { useEffect } from 'react'
import { notificationComponent } from 'redux/slices/NotificationSlice'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import CellVisualizationDirtyPrompt from './CellVisualizationDirtyPrompt'
import { CellVisualizationScene } from './CellVisualizationScene'
import useLoadCellInfoEffect from './arrow/useLoadCellInfoEffect'
import { PlotDataContextProvider } from './plot/usePlotData'
import { EXErrorWithMessage } from './useSessionApi'

export const CellVisualizationsPage = (): JSX.Element => {
  const { showError: showDeepcellError } = useDeepcellError()
  const { clearStore } = useCellVisualizationsSlice()
  const { resetRange } = usePlotlySlice()
  // TODO: Need to handle the errors
  const handleError = (error: unknown) => {
    const deepcellError = error as EXErrorWithMessage
    showDeepcellError(deepcellError?.data?.reason as ReasonCode, {
      message: deepcellError?.data?.error,
      component: notificationComponent.Toaster,
      severity: 'error',
    })
  }
  useLoadCellInfoEffect(handleError)

  // Use useEffect to return a cleanup function that runs when we leave this page
  // The dependencies below (clearStore and resetRange) should remain stable between re-renders
  // So React should run the cleanup function only once when the CellVisualizationPage component unmounts
  //
  // This code handles clearing all of the global state that lives outside of the CellVisualizationPage's
  // components when you leave the page, instead of our past appraoch of clearing the state
  // in multiple places as you enter the page. (fix for SWT-749)
  useEffect(() => {
    return () => {
      clearStore()
      resetRange()
    }
  }, [clearStore, resetRange])

  return (
    <PlotDataContextProvider>
      <CellVisualizationScene />
      <CellVisualizationDirtyPrompt />
    </PlotDataContextProvider>
  )
}

export default CellVisualizationsPage
